import * as RadixSelect from "@radix-ui/react-select";
import { cx } from "class-variance-authority";
import "components/Select/Select.css";
import { BottomSheet } from "components/Sheet";
import { useWindowSize } from "hooks/useWindowSize";
import { Arrow } from "icons/Arrow";
import type { ReactNode } from "react";
import { forwardRef, useState, useCallback, useEffect, useRef } from "react";

const DEFAULT_BORDER_STYLE = {
  borderColor: "#d4d8d4",
};

// Updated SelectItem component
const SelectItem = forwardRef<HTMLDivElement, RadixSelect.SelectItemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <RadixSelect.Item
        className={cx("SelectItem", className)}
        {...props}
        ref={forwardedRef}>
        <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        <RadixSelect.ItemIndicator className="SelectItemIndicator">
          {/* <CheckIcon /> */}
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    );
  }
);

interface Props {
  options: {
    value: any;
    label: string;
    background?: string;
    icon?: ReactNode;
  }[];
  onChange: (value: any) => void;
  disabled?: boolean;
  currentOption?: {
    value: any;
    label: string;
    background?: string;
    icon?: ReactNode;
  };
  classNames?: string; // pass sizing, etc to select
  fontSize?: string;
  textColor?: string;
  padding?: string;
  borderClass?: string;
  mobileTitle?: string;
  icon?: ReactNode;
  placeHolder?: string;
  style?: React.CSSProperties;
  isSearchable?: boolean;
  filterOption?: (option: any, inputValue: string) => boolean;
  defaultFilterOption?: boolean;
}

// Add debounce utility
function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export const Select = forwardRef<HTMLDivElement, Props>(
  (
    {
      options,
      onChange,
      disabled,
      currentOption,
      classNames,
      borderClass,
      fontSize,
      textColor,
      padding,
      icon,
      mobileTitle,
      placeHolder,
      isSearchable = false,
      filterOption,
      defaultFilterOption = true,
    },
    ref
  ) => {
    const defaultOption = currentOption || options[0];
    const defaultBackground = defaultOption ? defaultOption.background : "";
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
    const [selectedBackground, setSelectedBackground] = useState<string>(
      defaultBackground || ""
    );
    const [isSearching, setIsSearching] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [internalValue, setInternalValue] = useState(
      currentOption?.value ?? (placeHolder ? undefined : options[0]?.value)
    );
    const searchInputRef = useRef<HTMLInputElement>(null);

    // Update internal value when currentOption changes
    useEffect(() => {
      setInternalValue(
        currentOption?.value ?? (placeHolder ? undefined : options[0]?.value)
      );
    }, [currentOption, options, placeHolder]);

    // Focus search input when dropdown opens
    useEffect(() => {
      if (isOpen && isSearchable && searchInputRef.current) {
        setTimeout(() => {
          searchInputRef.current?.focus();
          setIsTyping(true);
        }, 0);
      }
    }, [isOpen, isSearchable]);

    // Default filter function
    const defaultFilterFunction = (option: any, input: string) => {
      const label = option.label.toLowerCase();
      const searchInput = input.toLowerCase();
      return (
        label === searchInput ||
        label.startsWith(searchInput) ||
        label.includes(searchInput)
      );
    };

    // Filter options based on debounced search value
    const filteredOptions =
      debouncedSearchValue && isSearchable
        ? options.filter((option) =>
            filterOption
              ? filterOption(option, debouncedSearchValue)
              : defaultFilterOption
              ? defaultFilterFunction(option, debouncedSearchValue)
              : true
          )
        : options;

    // Maintain focus only when typing
    useEffect(() => {
      if (isSearchable && searchInputRef.current && isTyping) {
        searchInputRef.current.focus();
      }
    }, [isTyping]);

    // Debounced search effect
    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedSearchValue(searchValue);
        setIsSearching(false);
      }, 300);

      return () => clearTimeout(timer);
    }, [searchValue]);

    const mobileClassNames = `
    ${classNames || ""} ${padding ?? "p-1 px-2"} ${
      borderClass || ""
    } border border-zinc-300 rounded-md max-w-full flex gap-2 items-center justify-between border-[${
      DEFAULT_BORDER_STYLE.borderColor
    }]
  `;
    const desktopClassNames = `
    ${classNames || ""} ${borderClass || ""} ${
      disabled ? "" : "hover:bg-[rgb(243,244,246)]"
    } ${
      disabled ? "border-faint-gray" : ""
    } SelectTrigger overflow-clip rounded-full border-[${
      DEFAULT_BORDER_STYLE.borderColor
    }]
  `;

    const { isMobile } = useWindowSize();

    // Handle search input
    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setSearchValue(e.target.value);
      setIsSearching(true);
    };

    // Handle value change
    const handleValueChange = (value: any) => {
      setInternalValue(value);
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption && selectedOption.background) {
        setSelectedBackground(selectedOption.background);
      }
      onChange(value);
      setSearchValue("");
      setIsTyping(false);
      setIsSearching(false);
    };

    if (isMobile) {
      return (
        <>
          <div
            tabIndex={0}
            className={mobileClassNames}
            onClick={() => {
              if (!disabled) setIsBottomSheetOpen(true);
            }}>
            {currentOption?.icon ? currentOption.icon : icon}
            <p
              className={`${fontSize ?? "text-sm font-light"} ${
                disabled ? "text-zinc-400" : ""
              } line-clamp-1`}>
              {currentOption?.value
                ? currentOption?.label ?? options[0]?.label
                : placeHolder}
            </p>
            <Arrow
              className="rotate-180 fill-brand-orange ml-1 min-w-[10px]"
              size={12}
            />
          </div>
          <BottomSheet
            isOpen={isBottomSheetOpen}
            onClose={() => setIsBottomSheetOpen(false)}>
            <div className="px-4 pb-20 font-light">
              <p className={`${fontSize ?? "text-lg"} mb-2`}>{mobileTitle}</p>
              {isSearchable && (
                <input
                  ref={searchInputRef}
                  type="text"
                  value={searchValue}
                  onChange={handleSearchInput}
                  placeholder="Search..."
                  className="w-full p-2 mb-2 border rounded-md bg-white text-black"
                  onKeyDown={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                  onBlur={(e) => e.stopPropagation()}
                />
              )}
              {(placeHolder
                ? [
                    {
                      value: undefined,
                      label: placeHolder,
                      background: null,
                      icon: null,
                    },
                    ...filteredOptions,
                  ]
                : filteredOptions
              ).map((option, index) => (
                <div
                  key={`${option.value}-${index}`}
                  className={`flex items-center py-2 ${
                    index < filteredOptions.length - 1 ? "border-b" : ""
                  } -ml-4 -mr-4 px-6 gap-1 `}
                  onClick={() => {
                    if (option.value) {
                      handleValueChange(option.value);
                      setIsBottomSheetOpen(false);
                    }
                  }}>
                  {option.background ? (
                    <div
                      className={`rounded-full w-2 h-2 mr-2 ${
                        option.background && `${option.background}`
                      }`}
                    />
                  ) : null}
                  {option.icon ? option.icon : icon}
                  <div>{option.label}</div>
                </div>
              ))}
            </div>
          </BottomSheet>
        </>
      );
    }

    return (
      <RadixSelect.Root
        onOpenChange={(open) => {
          setIsOpen(open);
          if (!open) {
            // Reset search state when closing
            setSearchValue("");
            setIsTyping(false);
            setIsSearching(false);
          }
        }}
        value={internalValue}
        onValueChange={handleValueChange}
        disabled={disabled}>
        <RadixSelect.Trigger
          className={desktopClassNames}
          aria-label="Select">
          <span
            className={`font-normal w-full ${
              disabled
                ? "text-zinc-400"
                : !!textColor
                ? textColor
                : "text-black text-left"
            } ${padding ?? "p-1 px-2"}`}>
            <RadixSelect.Value
              defaultValue={currentOption?.value || options[0]?.value}
            />
          </span>
          {disabled ? null : (
            <RadixSelect.Icon
              className={`SelectIcon ${disabled ? "saturate-0" : ""}`}>
              <Arrow
                className="rotate-180 fill-brand-orange ml-1"
                size={12}
              />
            </RadixSelect.Icon>
          )}
        </RadixSelect.Trigger>
        <RadixSelect.Portal>
          <RadixSelect.Content
            className="SelectContent z-50 max-h-[20rem] w-full bg-white flex flex-col"
            position="popper"
            side="bottom"
            align="start"
            sideOffset={5}
            onPointerDownOutside={() => setIsOpen(false)}
            onKeyDown={(e) => {
              // Prevent Radix's default keyboard navigation when we have a search field
              if (isSearchable && searchInputRef.current) {
                // Only handle single printable characters
                if (
                  e.key.length === 1 &&
                  !e.ctrlKey &&
                  !e.altKey &&
                  !e.metaKey
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                  searchInputRef.current.focus();
                  const newValue = searchValue + e.key;
                  searchInputRef.current.value = newValue;
                  setSearchValue(newValue);
                  setIsTyping(true);
                  setIsSearching(true);
                }
              }
            }}>
            {isSearchable && (
              <div
                className="px-1 py-2 bg-white border-b"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                <input
                  ref={searchInputRef}
                  type="text"
                  value={searchValue}
                  onChange={handleSearchInput}
                  placeholder="Search..."
                  className="w-full p-2 border rounded-md bg-white text-black"
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === "Escape") {
                      e.preventDefault();
                      setIsTyping(false);
                    }
                  }}
                  onFocus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsTyping(true);
                  }}
                  onBlur={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTimeout(() => {
                      if (!document.activeElement?.closest(".SelectContent")) {
                        setIsTyping(false);
                      }
                    }, 0);
                  }}
                />
              </div>
            )}
            <RadixSelect.Viewport className="SelectViewport overflow-y-auto">
              {(placeHolder
                ? [
                    {
                      value: undefined,
                      label: placeHolder,
                      background: null,
                      icon: null,
                    },
                    ...filteredOptions,
                  ]
                : filteredOptions
              ).map((option, index) => (
                <SelectItem
                  key={`option-${index}`}
                  value={option.value}
                  disabled={option.value === undefined}
                  className={`m-1 ${
                    disabled ? "" : "hover:brightness-90 focus:brightness-90"
                  }`}>
                  <div className="flex items-center w-full">
                    {option.background ? (
                      <div
                        className={`
                        rounded-full w-2 h-2 mr-2 
                        ${option.background}
                      `}></div>
                    ) : null}
                    <div
                      className={`
                      flex gap-2 w-full truncate items-center justify-center
                      ${fontSize ? fontSize : "text-sm"}
                      font-light
                      ${padding ? padding : "p-1 px-2"} 
                      ${option.label === placeHolder ? "text-gray-400" : ""}
                    `}>
                      {option.icon ? option.icon : icon}
                      <p>{option.label}</p>
                    </div>
                  </div>
                </SelectItem>
              ))}
            </RadixSelect.Viewport>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    );
  }
);
