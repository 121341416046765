import LoadingGoalsIcon from "assets/loading-goals.gif";
import NewCarePlanNotificationIcon from "assets/new_care_plan_notification.png";
import WarningGIF from "assets/warningGIF.gif";
import { useLatestCarePlan } from "backend/resources/carePlan/carePlan";
import {
  CarePlanGenerationStatus,
  CarePlanGenerationStatuses,
} from "backend/resources/carePlanGenerationStatus";
import { useHasPlanBinderControl } from "hooks/product/product";
import { CarePilotRoute, useAppNavigate } from "lib/routing";

export default function CarePlanStatusIcon({
  goalStatus,
}: {
  goalStatus: CarePlanGenerationStatus | undefined | null;
}) {
  const navigate = useAppNavigate();

  /// ////
  // Local State

  /// ////
  // Stores

  /// ////
  // Hooks

  const { data: hasCarePlanControl } = useHasPlanBinderControl();
  const { data: latestCarePlan } = useLatestCarePlan();

  const latestCarePlanCreationDate = latestCarePlan?.created_at;
  const hasNewSuggestedCarePlan =
    goalStatus?.status === CarePlanGenerationStatuses.COMPLETE &&
    (!latestCarePlanCreationDate ||
      goalStatus.created_at > latestCarePlanCreationDate);
  const hideIconBecauseAlreadyShowingInCarePlan = !latestCarePlan;

  const isPending = goalStatus?.status === CarePlanGenerationStatuses.PENDING;
  const isComplete = goalStatus?.status === CarePlanGenerationStatuses.COMPLETE;
  const isError = goalStatus?.status === CarePlanGenerationStatuses.ERROR;

  // We show the pending icon in CarePlan if it's the empty state, so we hide it here
  if (isPending && hideIconBecauseAlreadyShowingInCarePlan) {
    return null;
  }

  // TODO: Add refactor with switch case
  return (
    <>
      {/* Loading/Generated Messages */}
      {hasCarePlanControl && isPending ? (
        <div className="flex items-center pb-2 md:-mt-4 md:justify-end truncate w-full">
          <img
            className="w-8"
            src={LoadingGoalsIcon}
            alt=""
          />
          <p>A new suggested care plan is being created </p>
        </div>
      ) : null}
      {hasCarePlanControl && isComplete && hasNewSuggestedCarePlan ? (
        <button
          className="flex items-center pb-2 md:-mt-4 md:justify-end w-full "
          onClick={() => navigate({ path: CarePilotRoute.CARE_PLAN_EDIT })}>
          <img
            className="w-10"
            src={NewCarePlanNotificationIcon}
            alt=""
          />
          <p>You have a new suggested care plan </p>
        </button>
      ) : null}

      {hasCarePlanControl && isError ? (
        <button className="flex items-center pb-2 md:-mt-4 md:justify-end w-[100rem] cursor-default">
          <img
            className="w-10"
            src={WarningGIF}
            alt=""
          />
          <p>Something went wrong. Please retry care plan generation. </p>
        </button>
      ) : null}
    </>
  );
}
