import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useLatestMessages } from "backend/resources/chatGptMessage";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DiscussionFields } from "components/DiscussionComponent/components/DiscussionsFilter";
import CarePlanHighlights from "components/HomePage/CaregiverHome/CarePlanHighlights/CarePlanHighlights";
import "components/HomePage/CaregiverHome/CaregiverHome.css";
import { News } from "components/HomePage/CaregiverHome/News";
import UpcomingActivities from "components/HomePage/CaregiverHome/UpcomingActivities/UpcomingActivities";
import { ScheduleView } from "components/NanasDay/ScheduleView";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import DiscussionTable from "components/Tables/DiscussionTable";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "lib/routing";

export function CaregiverHome() {
  useAlfredPageSideBar("homePage");
  // Queries

  return (
    <PageContainer>
      <PageMainHeader text="Home" />
      <div className="w-full  max-w-[976px] flex flex-col mt-4  pb-20">
        <div className="flex flex-col">
          <UpcomingActivities />
          <MessagesSection />
        </div>
        <div className="flex flex-col gap-8">
          <MedicationSection />
          <CarePlanHighlights />
          <News />
        </div>
      </div>
    </PageContainer>
  );
}

function MessagesSection() {
  const navigate = useAppNavigate();

  const { data: latestMessages } = useLatestMessages();
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col">
      <ButtonWithIcon
        onClick={() =>
          navigate({
            path: CareCentralRoute.DISCUSSIONS,
          })
        }
        text={"Discussions"}
        icon={IconOption.ARROW}
        size="small"
        className="text-[22px] flex-row-reverse w-min"
      />
      <DiscussionTable
        data={
          latestMessages
            ?.sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .slice(0, 3) ?? []
        }
        hiddenColumns={[DiscussionFields.Carespace]}
      />
    </div>
  );
}

function MedicationSection() {
  const navigate = useAppNavigate();

  return (
    <div>
      <ButtonWithIcon
        onClick={() =>
          navigate({
            path: CarePilotRoute.MEDICATIONS,
          })
        }
        text={"Medications"}
        icon={IconOption.ARROW}
        size="small"
        className="text-[22px] flex-row-reverse w-min pb-5"
      />
      <ScheduleView />
    </div>
  );
}
