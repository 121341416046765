/**
 * @fileoverview Sign Up page.
 */

import GoogleLogo from "assets/google-logo.png";
import { signupWithInvitation } from "backend/functions";
import { supabase } from "clients/supabaseClient";
import { Button } from "components/Button";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { FancyLoadingState } from "components/FancyLoadingState";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TextInput } from "components/TextInput";
import { ExternalRoute, SharedRoute, useAppNavigate } from "lib/routing";
import type { ChangeEvent, FormEvent, MouseEvent, ReactElement } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useInvitationStore } from "state/invitation";
import { useIsAuthenticated, useUserStore } from "state/user";

/**
 * Component for the Sign Up Page.
 */

const WEAK_PASSWORD_ERROR = `Password should contain at least one character of each: abcdefghijklmnopqrstuvwxyz, ABCDEFGHIJKLMNOPQRSTUVWXYZ, 0123456789, !@#$%^&*()_+-=[]{};\\'\\:"|<>?,./\`~`;
const FORMATTED_WEAK_PASSWORD_ERROR =
  "Password must contain one of each of the following: lowercase, uppercase letters, digits and symbols";

export function SignUp() {
  const navigate = useAppNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  // Get the invitation_id from the URL
  const invitationId = queryParams.get("invitation_id");
  // we need to make it lower case since python sets it as True
  const isForOrganization =
    queryParams
      .get("isForOrganization")
      ?.toLocaleLowerCase()
      .includes("true") ?? false;
  const isAuthenticated = useIsAuthenticated();

  /////////
  // States
  /////////
  const [invitedEmail, setInvitedEmail] = useState<string | null>("");
  const [email, setEmail] = useState("");
  const [isWaitingForConfirmationEmail, setIsWaitingForConfirmationEmail] =
    useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [password, setPassword] = useState("");
  const [userAlreadyHasAccount, setUserAlreadyHasAccount] = useState(false);
  const signUp = useUserStore((state) => state.signUp);
  const [isSetupDone, setIsSetupDone] = useState(false);
  const [error, setError] = useState<string | null | ReactElement>();
  /////////
  // Stores
  /////////
  const setInvitationId = useInvitationStore((state) => state.setInvitationId);
  const setIsForOrganization = useInvitationStore(
    (state) => state.setIsForOrganization
  );

  /////////
  // Effects
  /////////
  useEffect(() => {
    async function setup() {
      // Function to extract query parameters from the URL
      if (isAuthenticated) {
        navigate({
          path: SharedRoute.HOME,
          queryParams: { "accepted-invitation": String(true) },
        });
      }
      setInvitationId(invitationId);
      setInvitedEmail(invitedEmail);
      setEmail(invitedEmail || "");
      setIsForOrganization(isForOrganization);
      setIsSetupDone(true);
    }
    setup();
  }, [isAuthenticated]);

  async function handleSignUp(
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    setIsSigningUp(true);

    // we don't ask for emails in invitations
    if (!email && !invitationId) {
      setError("You must enter your email");
    } else if (!password) {
      setError("You must enter your password");
    } else {
      setIsWaitingForConfirmationEmail(true);
      const result = invitationId
        ? await signupWithInvitation(password, invitationId, isForOrganization)
        : await signUp({ email, password });

      if (result?.error) {
        setIsWaitingForConfirmationEmail(false);
        // show error message wherever it's present
        const error = result.error.message
          ? result.error.message
          : result.error;
        setError(
          error.replace(WEAK_PASSWORD_ERROR, FORMATTED_WEAK_PASSWORD_ERROR)
        );
      }
    }

    setIsSigningUp(false);
  }

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setError(null);
    setEmail(e.currentTarget.value);
  }

  function onChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setError(null);
    setPassword(e.currentTarget.value);
  }

  async function signInWithGoogle() {
    setIsSigningUp(true);
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });
    if (error) {
      setError("Something went wrong.");
    }
    setIsSigningUp(false);
  }
  if (isAuthenticated) {
    // This prevents the brief flash when signing up until redirected home
    return null;
  } else if (isWaitingForConfirmationEmail) {
    return (
      <div className="fixed top-1/2  transform -translate-y-1/2  bg-white w-full flex flex-col items-center ">
        <div className="px-5 text-center py-5">
          <p>
            Please check your email and click on the link provided to complete
            the sign-up process.
          </p>
          <p>Note that it might take a few minutes for the email to arrive.</p>
        </div>
      </div>
    );
  } else if (!isSetupDone) {
    return (
      <div className="fixed top-1/2  transform -translate-y-1/2  bg-white w-full flex flex-col items-center ">
        <FancyLoadingState />
      </div>
    );
  } else if (isForOrganization && invitationId) {
    return (
      <div className="bg-white w-full h-full flex sm:items-center justify-center overflow-y-scroll">
        <div className="md:min-w-[500px] bg-white px-3 sm:px-12 sm:pt-20 sm:pb-12 rounded-md flex flex-col items-center gap-4">
          <h1 className="text-xl text-center">
            You've been invited to join an organization
          </h1>
          <div className="flex md:flex-row flex-col w-full justify-center text-center">
            <div className="p-10 md:border-r border-b flex flex-col gap-5 md:w-[50%]">
              <p> If you don't have an account, please create a password </p>
              <div className="flex gap-2 justify-center">
                <TextInput
                  data-hj-allow
                  type="password"
                  onChange={onChangePassword}
                  placeholder="Enter a password"
                  value={password}
                />
                <ButtonWithIcon
                  icon={IconOption.ARROW}
                  text="Submit"
                  size="small"
                  onClick={handleSignUp}
                />
              </div>
              <SignUpWithGoogle signInWithGoogle={signInWithGoogle} />
              <ErrorMessage
                error={error}
                userAlreadyHasAccount={userAlreadyHasAccount}
              />
            </div>
            <div className="p-10 md:w-[50%] flex flex-col justify-center">
              <p> If you have an account, please sign in. </p>
              <Button
                className="md:w-[80%] mt-4 rounded-md self-center"
                onClick={() => {
                  navigate({
                    path: SharedRoute.LOGIN,
                    queryParams: Object.fromEntries(
                      new URLSearchParams(window.location.search)
                    ),
                  });
                }}>
                Sign In
              </Button>
            </div>
          </div>
          <AgreementLine />
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white w-full h-full flex sm:items-center justify-center overflow-y-scroll">
        <div className="md:min-w-[500px] bg-white px-3 sm:px-12 sm:pt-20 sm:pb-12 rounded-md flex flex-col items-center gap-4">
          <h1 className="text-xl md:text-3xl text-center">
            {invitationId
              ? isForOrganization
                ? "You've been invited to join an organization"
                : "You've been invited to join a caregiving team"
              : "Create an account"}
          </h1>
          {invitationId ? (
            <p className="w-full text-center md:w-[38rem] p-2 md:p-0 md:mt-10 md:mb-4 text-sm sm:text-base">
              Please enter a password for your new account
            </p>
          ) : null}
          <form
            onSubmit={handleSignUp}
            className="w-11/12 sm:w-[60%] flex flex-col items-center">
            {!invitationId ? (
              <div className="w-full flex flex-col mt-2">
                <label
                  className="text-black text-xs ml-1"
                  htmlFor="email">
                  Email
                </label>
                <TextInput
                  data-hj-allow
                  type="email"
                  onChange={onChangeEmail}
                  disabled={!!invitedEmail}
                  placeholder="Enter your email address"
                  value={email}
                />
              </div>
            ) : null}
            <div className="w-full flex flex-col">
              <label
                className="text-black text-xs ml-1 mt-3"
                htmlFor="password">
                Password
              </label>
              <TextInput
                data-hj-allow
                type="password"
                onChange={onChangePassword}
                placeholder="Enter a password"
                value={password}
              />
              {isSigningUp ? (
                <LoadingSpinner className="w-4 h-4 my-3 self-center" />
              ) : null}
            </div>
            <ErrorMessage
              error={error}
              userAlreadyHasAccount={userAlreadyHasAccount}
            />
            <Button
              className="w-full mt-4"
              onClick={handleSignUp}>
              Sign Up
            </Button>
          </form>
          <SignUpWithGoogle signInWithGoogle={signInWithGoogle} />
          <AgreementLine />
          <div className="mt-3 md:self-end self-center flex text-[14px] md:text-base pb-6">
            <p className="mr-1">Already have an account?</p>
            <Link
              className="text-blue-500 hover:text-blue-800"
              to={{
                pathname: "/",
                search: window.location.search,
              }}>
              Sign in
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function AgreementLine() {
  return (
    <>
      <div className="relative flex justify-center h-2 border-t border-faint-gray w-full mt-4">
        <div className="absolute -top-3 text-gray-400 border border-faint-gray z-10 px-1 rounded-lg bg-white"></div>
      </div>
      <span className="text-center sm:text-left text-xs sm:text-sm">
        By signing up, you agree to the{" "}
        <a
          className="text-blue-500 hover:text-blue-800"
          href={ExternalRoute.TERMS_OF_SERVICE}>
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          className="text-blue-500 hover:text-blue-800"
          href={ExternalRoute.PRIVACY_POLICY}>
          Privacy Policy
        </a>{" "}
        , including{" "}
        <a
          className="text-blue-500 hover:text-blue-800"
          href={ExternalRoute.COOKIES_POLICY}>
          Cookie use
        </a>
        .
      </span>
    </>
  );
}

function SignUpWithGoogle({
  signInWithGoogle,
}: {
  signInWithGoogle: () => void;
}) {
  return (
    <>
      {" "}
      <div className="relative flex justify-center h-2 border-t border-faint-gray w-full mt-4 ">
        <div className="absolute -top-3 text-gray-400 border border-faint-gray z-10 px-1 rounded-lg bg-white">
          OR
        </div>
      </div>
      <button
        className="mt-2 flex items-center gap-3 border rounded-sm 7 p-2.5 md:w-[220px] self-center"
        onClick={signInWithGoogle}>
        <img
          className="w-4 h-4 self-center"
          src={GoogleLogo}
          width="50%"
          alt="google-icon"
        />
        <p className="font-semibold text-gray-500">Sign up with Google</p>
      </button>
    </>
  );
}

function ErrorMessage({
  error,
  userAlreadyHasAccount,
}: {
  error: string | null | undefined | ReactElement;
  userAlreadyHasAccount: boolean;
}) {
  return (
    <>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {userAlreadyHasAccount && (
        <div className="mt-2">
          <p className="text-sm break-words mb-2">
            Looks like you already have an account with us! Click the log in
            button below.
          </p>
        </div>
      )}
    </>
  );
}
