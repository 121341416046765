import { Cell, Row, flexRender } from "@tanstack/react-table";
import { ServiceResourceWithServiceEngagements } from "backend/resources/services/serviceResource";
import { useServicesFilteredData } from "components/Services Hub/servicesUtil";
import {
  InfiniteScrollingTable,
  TableDataRowType,
} from "components/Tables/InfiniteScrollingTable";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useEffect, useState } from "react";
import { ServiceFilterTypes } from "state/serviceFilters/serviceFilters";

// Define your service fields and types
export enum ServiceProviderFields {
  RowData = "rowData",
  Name = "Name",
  ServiceType = "Service Type",
  ActivePatients = "# of Active Patients",
  InactivePatients = " # of Inactive Patients",
  AddedOn = "Added On",
}
type BaseServiceProviderType = {
  [ServiceProviderFields.RowData]: ServiceResourceWithServiceEngagements;
  [ServiceProviderFields.Name]: string;
  [ServiceProviderFields.ServiceType]: string;
  [ServiceProviderFields.ActivePatients]: number;
  [ServiceProviderFields.InactivePatients]: number;
  [ServiceProviderFields.AddedOn]: string;
};
export type ServiceProviderType = BaseServiceProviderType &
  TableDataRowType<BaseServiceProviderType>;

const FILTER_CONFIG = {
  [ServiceFilterTypes.ORG]: true,
  [ServiceFilterTypes.CARESPACE]: true,
  [ServiceFilterTypes.PROVIDER]: true,
  [ServiceFilterTypes.SERVICE_TYPE]: true,
  [ServiceFilterTypes.HIDE_INACTIVE]: true,
};

export default function ServiceProvidersTable({
  data: rawData,
  hiddenColumns = [],
}: {
  data: ServiceResourceWithServiceEngagements[];
  hiddenColumns?: ServiceProviderFields[];
}) {
  const [data, setData] = useState<ServiceProviderType[]>(() => []);

  const filteredData = useServicesFilteredData({
    data: rawData!!,
    config: FILTER_CONFIG,
  });

  useEffect(() => {
    // Updates data
    if (filteredData) {
      const updatedData = filteredData.map((item) => ({
        [ServiceProviderFields.RowData]: item,
        [ServiceProviderFields.Name]: item.name,
        [ServiceProviderFields.ServiceType]: item.categories ?? "N/A",
        [ServiceProviderFields.ActivePatients]: item.service_engagement?.filter(
          (engagement) => engagement.is_active
        ).length,
        [ServiceProviderFields.InactivePatients]:
          item.service_engagement?.filter((engagement) => !engagement.is_active)
            .length,
        [ServiceProviderFields.AddedOn]: new Date(
          item.created_at
        ).toLocaleDateString(),
      }));
      setData(updatedData);
    }
  }, [filteredData]);

  const navigate = useAppNavigate();
  return (
    <InfiniteScrollingTable
      data={data}
      mobileColumns={[]}
      headersToCenter={[
        ServiceProviderFields.ActivePatients,
        ServiceProviderFields.InactivePatients,
      ]}
      columnFields={Object.values(ServiceProviderFields)}
      handleClick={function (
        cell: Cell<ServiceProviderType, unknown>,
        row: Row<ServiceProviderType>
      ): void {
        navigate({
          path: SharedRoute.SERVICE_PROVIDER_PAGE,
          params: {
            id: row.original[ServiceProviderFields.RowData].id,
          },
        });
      }}
      hiddenColumns={[ServiceProviderFields.RowData, ...hiddenColumns]}
      CellContent={({
        cell,
        row,
      }: {
        cell: Cell<ServiceProviderType, unknown>;
        row: Row<ServiceProviderType>;
      }): JSX.Element => {
        switch (cell.column.id) {
          case ServiceProviderFields.ActivePatients:
          case ServiceProviderFields.InactivePatients:
            return (
              <p className={`rounded-full truncate text-center w-[120px]`}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </p>
            );
          default:
            return (
              <p
                className={`rounded-full truncate text-left w-[250px] max-w-[250px]`}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </p>
            );
        }
      }}
    />
  );
}
