import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeleteGoalTemplateMutation,
  useGoalTemplateQueryById,
  useSaveGoalTemplateMutation,
} from "backend/resources/goalTemplate/goalTemplate";
import { useRecommendationsForTemplate } from "backend/resources/goalTemplate/goalTemplateToRecommendation";
import type { ShareableRecommendationType } from "backend/resources/shareableRecommendation/shareableRecommendation";
import { useShareableRecommendationsFromShareableRecommendationIds } from "backend/resources/shareableRecommendation/shareableRecommendation";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useGoalStore } from "state/goal/goal";
import { useActiveOrganizationId } from "state/organization/organization";
import { useUserStore } from "state/user";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CloseButton } from "components/CloseButton/CloseButton";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { ShareableRecommendation } from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableRecommendation";
import ModalView from "components/ModalView/ModalView";
import { PageContainer } from "components/PageContainer";
import { FormContainer } from "components/PageContainer/FormContainer";
import { PageMainHeader } from "components/PageMainHeader";
import ResponsivePopup from "components/ResponsivePopup/ResponsivePopup";
import { Select } from "components/Select";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import type { Timeline } from "components/GoalPage/utils";
import { TimeFrame } from "components/GoalPage/utils";
import { GoalTemplateAddPopover } from "components/GoalPage/GoalTemplate/GoalTemplateSelectPopover";
import { ShareableRecommendationAddPopover } from "components/GoalPage/GoalTemplate/ShareableRecommendationSelectPopover";

export default function CreateOrEditGoalTemplatePage() {
  const { id } = useParams();
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();
  const { resetSession } = useGoalStore();

  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        title={id ? "Edit Goal" : "Add Goal"}
        closeText="Back"
        onClose={() => {
          resetSession(id ?? "");
          navigateBack();
        }}>
        <PageContainer>
          <CreateOrEditGoalPageComponent />
        </PageContainer>
      </ModalView>
    );
  } else {
    return (
      <PageContainer>
        <ButtonWithIcon
          onClick={() => {
            resetSession(id ?? "");
            navigateBack();
          }}
          text="Back"
          size="small"
          className="w-full justify-center flex md:justify-start mb-4"
          icon={IconOption.BACK_ARROW}
        />
        <PageMainHeader text={id ? "Edit Goal" : "Add goal"} />
        <CreateOrEditGoalPageComponent />
      </PageContainer>
    );
  }
}

function CreateOrEditGoalPageComponent() {
  /// //////
  // Vars
  /// /////
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const { id: goalTemplateId } = useParams();
  /// //////
  // Local State
  /// /////

  const [
    isAddRecommendationsPopupShowing,
    setIsAddRecommendationsPopupShowing,
  ] = useState(false);

  const [isAddFromTemplatesPopupShowing, setIsAddFromTemplatesPopupShowing] =
    useState(false);

  const [
    isAddRecommendationsFromTemplatePopupShowing,
    setIsAddRecommendationsFromTemplatePopupShowing,
  ] = useState(false);

  const [isDeleteTemplatePopupShowing, setIsDeleteTemplatePopupShowing] =
    useState(false);

  const [localShareableRecommendations, setLocalShareableRecommendations] =
    useState<ShareableRecommendationType[]>([]);

  /// //////
  // Stores
  /// /////

  const { setSession, sessions, resetSession } = useGoalStore();
  const { title, details, timeframe, recommendationIds } = sessions[
    goalTemplateId ?? ""
  ] ?? { recommendationIds: [] };
  const authUser = useUserStore((state) => state.user);

  /// //////
  // Hooks/Mutations
  /// /////

  const { data: shareableRecommendations } =
    useShareableRecommendationsFromShareableRecommendationIds(
      recommendationIds ?? []
    );
  const { data: existingRecommendations } =
    useRecommendationsForTemplate(goalTemplateId);
  const { goalTemplate } = useGoalTemplateQueryById(goalTemplateId);

  const activeOrganizationId = useActiveOrganizationId();

  // Mutation
  const saveGoalTemplate =
    useSaveGoalTemplateMutation(goalTemplateId).mutateAsync;
  const deleteGoalTemplateMutation =
    useDeleteGoalTemplateMutation().mutateAsync;

  // Handler

  async function onSave() {
    if (title && details && timeframe && activeOrganizationId && authUser) {
      await saveGoalTemplate({
        goalTemplate: {
          ...goalTemplate,
          title,
          description: details,
          timeline: timeframe,
          organization_id: activeOrganizationId,
          user_id: authUser.id,
        },
        recommendations: localShareableRecommendations.map((rec) => ({
          id: rec.id,
          title: rec.title,
          details: rec.details,
          interventions: [],
        })),
        isUserTemplate: true,
      });
    }
    resetSession(goalTemplateId ?? "");
  }

  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    if (!hasInitialized) {
      const existingSession = sessions[goalTemplateId ?? ""] ?? {};
      const updatedSession = {
        title: existingSession.title ?? goalTemplate?.title ?? "",
        details: existingSession.details ?? goalTemplate?.description ?? "",
        timeframe:
          existingSession.timeframe ?? (goalTemplate?.timeline as Timeline),
        recommendationIds: existingSession.recommendationIds?.length
          ? existingSession.recommendationIds
          : [
              ...(existingRecommendations?.map((rec) => rec.id) ?? []),
              ...recommendationIds,
            ],
      };

      setSession(goalTemplateId ?? "", updatedSession);
      setHasInitialized(true);
    }
  }, [existingRecommendations, goalTemplate, goalTemplateId, hasInitialized]);

  useEffect(() => {
    setLocalShareableRecommendations(shareableRecommendations ?? []);
  }, [shareableRecommendations]);

  return (
    <div className="md:pt-10 flex flex-col gap-4 font-light">
      <ButtonWithIcon
        onClick={() => {
          setIsAddFromTemplatesPopupShowing(true);
        }}
        text={"Add from templates"}
        size="small"
        className="w-full justify-center flex md:justify-start"
        icon={IconOption.PLUS}
      />
      <FormContainer className="flex flex-col gap-4">
        {/* Title */}
        <label>Title</label>
        <TextInput
          value={title}
          onChange={(e) =>
            setSession(goalTemplateId ?? "", {
              ...sessions[goalTemplateId ?? ""],
              title: e.target.value,
            })
          }
        />
        {/* Details */}
        <label>Details</label>
        <TextArea
          value={details}
          onChange={(e) =>
            setSession(goalTemplateId ?? "", {
              ...sessions[goalTemplateId ?? ""],
              details: e.currentTarget.value,
            })
          }
        />
        <div className="flex gap-1 items-center">
          <label>Time Frame:</label>
          <Select
            placeHolder="Please select"
            currentOption={TimeFrame.getDropDownOptions().find(
              (option) => option.value === timeframe
            )}
            options={TimeFrame.getDropDownOptions()}
            onChange={(item) => {
              setSession(goalTemplateId ?? "", {
                ...sessions[goalTemplateId ?? ""],
                timeframe: item,
              });
            }}
          />
        </div>
        <ButtonWithIcon
          onClick={() => {
            setIsAddRecommendationsPopupShowing(true);
          }}
          text={"Recommendations"}
          size={"small"}
          className="flex flex-row-reverse justify-end my-4"
          icon={IconOption.PLUS}
        />
        {localShareableRecommendations?.map((shareableRecommendation) => (
          <div className="relative w-min">
            <button
              type="button"
              className="absolute top-2 right-1 hover:scale-[1.15] transition-transform z-[2]"
              onClick={(event) => {
                event.preventDefault();

                setSession(goalTemplateId ?? "", {
                  ...sessions[goalTemplateId ?? ""],
                  recommendationIds: recommendationIds.filter(
                    (recommendation) =>
                      recommendation !== shareableRecommendation.id
                  ),
                });
              }}>
              <CloseButton />
            </button>
            <ShareableRecommendation recommendation={shareableRecommendation} />
          </div>
        ))}
      </FormContainer>

      <GoalTemplateAddPopover
        close={() => setIsAddFromTemplatesPopupShowing(false)}
        isOpen={isAddFromTemplatesPopupShowing}
      />
      <ShareableRecommendationAddPopover
        close={() => setIsAddRecommendationsFromTemplatePopupShowing(false)}
        isOpen={isAddRecommendationsFromTemplatePopupShowing}
        goalId={goalTemplateId ?? ""}
      />
      <ResponsivePopup
        isOpen={isAddRecommendationsPopupShowing}
        onClose={() => setIsAddRecommendationsPopupShowing(false)}>
        <div className="font-light flex flex-col gap-3 pb-10 md:pb-0">
          <header className="w-full text-center text-lg pb-4">
            Add Recommendations
          </header>
          <ButtonWithIcon
            onClick={() => {
              setIsAddRecommendationsPopupShowing(false);
              setIsAddRecommendationsFromTemplatePopupShowing(true);
            }}
            size={"small"}
            text={"Add From Templates"}
            icon={IconOption.ARROW}
          />
        </div>
      </ResponsivePopup>
      <ResponsivePopup
        isOpen={isDeleteTemplatePopupShowing}
        onClose={() => setIsDeleteTemplatePopupShowing(false)}>
        <div className="p-4 font-light flex flex-col gap-3 pb-20 md:pb-0">
          <header className="w-full text-center text-lg pb-4">
            Are you sure you want to delete this template?
          </header>
          <FooterButtons>
            <ButtonWithIcon
              onClick={() => {
                setIsDeleteTemplatePopupShowing(false);
              }}
              size={"small"}
              text={"Cancel"}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={async () => {
                if (goalTemplate) {
                  await deleteGoalTemplateMutation(goalTemplate.id);
                }
                resetSession(goalTemplateId ?? "");
                navigate({
                  path: CareCentralRoute.TEMPLATES,
                });
              }}
              size={"small"}
              text={"Yes, Delete it"}
              icon={IconOption.TRASH}
            />
          </FooterButtons>
        </div>
      </ResponsivePopup>
      <FooterButtons>
        {goalTemplateId ? (
          <ButtonWithIcon
            onClick={() => setIsDeleteTemplatePopupShowing(true)}
            text={"Delete"}
            size={"small"}
            icon={IconOption.TRASH}
          />
        ) : null}
        <ButtonWithIcon
          onClick={() => {
            resetSession(goalTemplateId ?? "");
            navigateBack();
          }}
          text={"Cancel"}
          size={"small"}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          onClick={() => {
            onSave();
            navigateBack();
          }}
          text={"Save"}
          disabled={!title || !details || !timeframe}
          size={"small"}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
    </div>
  );
}
