import "components/PhoneNumberInput/phoneNumberInput.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function PhoneNumberInput({
  cellPhone,
  setCellPhone,
  handleFocus,
  className,
}: {
  handleFocus?: any;
  cellPhone: string | undefined;
  setCellPhone: any;
  className?: string;
}) {
  return (
    <PhoneInput
      className={`bg-white flex border-2  border-faint-gray p-1 pl-2 rounded-lg overflow-hidden w-full ${className}`}
      international
      defaultCountry="US"
      placeholder="Enter phone number"
      value={cellPhone || undefined}
      onFocus={handleFocus}
      onChange={setCellPhone}
      limitMaxLength={true}
    />
  );
}

export function PhoneNumberFormInput({
  onChange,
  handleFocus,
  value,
}: {
  handleFocus?: any;
  onChange: any;
  value?: string;
}) {
  return (
    <PhoneInput
      className={
        "flex h-10 w-full py-2 pl-2 text-sm rounded-md border border-neutral-200 bg-white ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      }
      international
      value={value}
      defaultCountry="US"
      placeholder="Enter phone number"
      onFocus={handleFocus}
      onChange={onChange}
    />
  );
}
