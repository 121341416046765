import {
  TaskStatus,
  TaskStatusLabel,
  useServiceRequests,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import { DefaultCheckbox } from "components/Checkbox";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Select } from "components/Select";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import {
  TaskTableFields,
  TaskTableType,
} from "components/Tables/TaskTable/TaskTableTypes";
import {
  TaskFilterTypes,
  TaskFilters,
} from "components/TaskNavigatorPage/TaskFilters";
import { TaskHighPieChart } from "components/TaskNavigatorPage/TaskHighPieChart";
import { generateTitleHeader } from "components/TaskNavigatorPage/taskTableUtils";
import { useAppNavigate } from "lib/routing";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";

export default function ServiceRequestsTab() {
  const navigate = useAppNavigate();

  const { setStatus, status, hideDone, setHideDone } = useTaskFilterStore();
  const { isLoading, data: serviceRequests } = useServiceRequests();
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;

  function sortRowsFunction(a: TaskTableType, b: TaskTableType) {
    if (new Date(a.Due).getTime() !== new Date(b.Due).getTime()) {
      return new Date(a.Due) < new Date(b.Due) ? -1 : 1;
    } else {
      const carespaceComparison = a.Carespace.localeCompare(b.Carespace);
      if (carespaceComparison !== 0) return carespaceComparison;
      return a[TaskTableFields.ServiceStatus].localeCompare(
        b[TaskTableFields.ServiceStatus]
      );
    }
  }

  if (isLoading) {
    return <LoadingSpinner className="w-40 m-auto" />;
  }
  return (
    <div className=" flex flex-col gap-4 pb-20">
      <h1 className="text-xl mt-3">
        {generateTitleHeader("Request Status", serviceRequests ?? [])}
      </h1>
      <div className="flex flex-col xl:flex-row gap-4 w-[1400px]">
        <div className="w-full xl:w-1/3">
          <TaskHighPieChart
            data={serviceRequests ?? []}
            isLoading={isLoading}
          />
        </div>
        <div className="w-full xl:w-1/3">
          <TaskFilters
            filterConfig={{
              [TaskFilterTypes.CARESPACE]: true,
              [TaskFilterTypes.WHO]: true,
              [TaskFilterTypes.DUE_DATE]: true,
            }}
            shouldStackInputs={true}
          />
        </div>
      </div>
      <div className="flex gap-20 items-center">
        <div className="flex gap-5 items-center">
          <p>Status</p>
          <Select
            currentOption={
              status
                ? { value: status, label: status }
                : { value: "All", label: "All" }
            }
            options={[
              { value: "All", label: "All" },
              ...Object.values(TaskStatus).map((status) => ({
                label: TaskStatusLabel[status],
                value: status,
              })),
              { value: "Overdue", label: "Overdue" },
            ]}
            onChange={(status) => setStatus(status)}
          />
          {status === "All" && (
            <div className="flex gap-2 items-center">
              <DefaultCheckbox
                checked={hideDone}
                onChange={(isChecked: boolean) => setHideDone(isChecked)}
              />
              <p className="text-sm">Hide Done</p>
            </div>
          )}
        </div>
      </div>
      <TaskTable
        updateTask={updatePlanEntry}
        data={serviceRequests}
        hideCheckbox
        overriddenHeaderNames={{
          [TaskTableFields.Status]: "Request Status",
        }}
        filterConfig={{
          [TaskFilterTypes.STATUS]: true,
          [TaskFilterTypes.CARESPACE]: true,
          [TaskFilterTypes.WHO]: true,
          [TaskFilterTypes.DUE_DATE]: true,
          [TaskFilterTypes.HIDE_DONE]: true,
        }}
        sortFunction={sortRowsFunction}
        hiddenColumns={[TaskTableFields.Category]}
      />
    </div>
  );
}
