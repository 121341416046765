import { UserAssessmentAnswer } from "backend/resources/userAssessmentAnswer";
import { YesOrNoOptions } from "components/AssessmentComponent/Input/inputTypes/YesOrNoInput";
import { DefaultCheckbox } from "components/Checkbox";
import { useEffect } from "react";
import create from "zustand";

interface AssessmentCheckboxProps {
  currentAnswer: UserAssessmentAnswer | undefined;
  updateAnswer: (obj: { answer?: string; context?: string }) => void;
  disabled: boolean;
}

export function AssessmentCheckbox({
  currentAnswer,
  updateAnswer,
  disabled,
}: AssessmentCheckboxProps) {
  const { selectedRadioButtonAnswerId, setSelectedRadioButtonAnswerId } =
    useRadioButtonStore();
  useEffect(() => {
    if (!disabled) {
      updateAnswer({
        answer:
          currentAnswer && currentAnswer?.id === selectedRadioButtonAnswerId
            ? YesOrNoOptions.YES
            : YesOrNoOptions.NO,
      });
    }
  }, [selectedRadioButtonAnswerId]);

  useEffect(() => {
    if (currentAnswer && currentAnswer.answer === YesOrNoOptions.YES) {
      setSelectedRadioButtonAnswerId(currentAnswer.id);
    }
  }, [currentAnswer]);

  return (
    <div className="flex justify-center items-center">
      <DefaultCheckbox
        checked={
          !!selectedRadioButtonAnswerId &&
          selectedRadioButtonAnswerId === currentAnswer?.id
        }
        disabled={disabled}
        onChange={() => {
          setSelectedRadioButtonAnswerId(currentAnswer?.id || null);
        }}
      />
    </div>
  );
}

interface RadioButtonStore {
  selectedRadioButtonAnswerId: string | null;
  setSelectedRadioButtonAnswerId: (questionId: string | null) => void;
}

export const useRadioButtonStore = create<RadioButtonStore>((set) => ({
  selectedRadioButtonAnswerId: null,
  setSelectedRadioButtonAnswerId: (answerId) =>
    set({ selectedRadioButtonAnswerId: answerId }),
}));
