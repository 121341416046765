import { useOrgs } from "backend/resources/orgRole";
import { AppLayout } from "components/App/AppLayout";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "lib/routing";
import type { ReactElement } from "react";
import { useCallback } from "react";
import { Route } from "react-router-dom";
import type { RoutLayoutObject } from "routes";
import { ProtectedRoute, RouteLayout } from "routes";
import { useActiveNetworkId } from "state/network/network";

export function renderRoute(route: RoutLayoutObject) {
  const { routeLayout, element, path, hideSideNav } = route;
  // Start with the base element
  let finalElement = element;

  // Wrap with the parentElement if it's provided
  if (routeLayout === RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT) {
    finalElement = (
      <ProtectedRoute component={<AppLayout>{finalElement}</AppLayout>} />
    );
  }
  if (routeLayout === RouteLayout.PROTECTED_ROUTE) {
    finalElement = <ProtectedRoute component={finalElement as ReactElement} />;
  }
  if (routeLayout === RouteLayout.APP_LAYOUT) {
    finalElement = (
      <AppLayout hideSideNav={hideSideNav}>{finalElement}</AppLayout>
    );
  }

  return (
    <Route
      key={path}
      path={path}
      element={finalElement}
    />
  );
}

// attempts to navigate to a carespace tab
// if the user does not have carecentral access, it will navigate to the carepilot tab

export function useNavigateToCarespaceTab() {
  const { hasCareCentralAccess } = useOrgs();
  const navigate = useAppNavigate();
  const _networkId = useActiveNetworkId();

  return useCallback(
    (
      carespaceTab: CarespacePageTabs,
      options: {
        replace?: boolean;
        networkId?: string | undefined;
        queryParams?: Record<string, string>;
      } = { replace: false, networkId: _networkId, queryParams: {} }
    ) => {
      if (hasCareCentralAccess && options.networkId) {
        navigate(
          {
            path: CareCentralRoute.CARESPACE,
            params: { carespaceId: options.networkId },
            queryParams: { tab: carespaceTab, ...options.queryParams },
          },
          { replace: options.replace }
        );
      } else {
        const carespaceTabToCarepilotRoute: Record<
          CarespacePageTabs,
          CarePilotRoute
        > = {
          [CarespacePageTabs.CARE_PLAN]: CarePilotRoute.MY_CARE,
          [CarespacePageTabs.CARE_TEAM]: CarePilotRoute.MY_CARE,
          [CarespacePageTabs.DIAGNOSIS]: CarePilotRoute.MY_CARE,
          [CarespacePageTabs.ASSESSMENTS]: CarePilotRoute.ASSESSMENTS,
          [CarespacePageTabs.COACHING_PLAN]: CarePilotRoute.CARE_PLAN,
          [CarespacePageTabs.MEDICATIONS]: CarePilotRoute.MEDICATIONS,
        };

        navigate(
          {
            path: carespaceTabToCarepilotRoute[carespaceTab] as any,
            queryParams: options.queryParams,
          },
          { replace: options.replace }
        );
      }
    },
    [hasCareCentralAccess, navigate, _networkId]
  );
}
