import { TextField } from "@mui/material";
import type { DispensableDrug, DrugClass } from "backend/functions";
import type { UserPrescriptionInsert } from "backend/resources/userPrescription/userPrescription";
import { useUpsertUserPrescription } from "backend/resources/userPrescription/userPrescription";
import type { UserPrescriptionScheduledDoseInsert } from "backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import {
  TimeOfDay,
  useUpsertScheduledDoses,
} from "backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { DefaultCheckbox } from "components/Checkbox";
import FooterButtons from "components/FooterButtons/FooterButtons";
import ModalView from "components/ModalView/ModalView";
import { PageMainHeader } from "components/PageMainHeader";
import { RadioButtonsGroup } from "components/RadioGroup";
import { Select } from "components/Select";
import { TextArea } from "components/TextArea";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigateToCarespaceTab } from "routes/routesUtil";
import { useUserStore } from "state/user";
import { capitalizeFirstLetter } from "utils";
import { DrugAutocomplete } from "./DrugAutocomplete";
import { getPluralDrugDoseDesc } from "./utils";

export function AddPrescriptionView() {
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();
  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        onClose={() => {
          navigateBack();
        }}
        closeText="Back">
        <AddPrescriptionViewComponents />
      </ModalView>
    );
  } else {
    return <AddPrescriptionViewComponents />;
  }
}

function AddPrescriptionViewComponents() {
  const navigateToCarespaceTab = useNavigateToCarespaceTab();

  // form state
  const [searchParams, setSearchParams] = useSearchParams();
  const [formPage, setFormPage] = useState<1 | 2>(1);
  // - page 1
  const authUser = useUserStore((state) => state.user);
  const { isMobile } = useWindowSize();
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const [selectedDrugClass, setSelectedDrugClass] =
    useState<DrugClass | null>();
  const [selectedDispensableDrug, setSelectedDispensableDrug] = useState<{
    dispensableDrugId: string;
    medStrength: string;
    medStrengthUnit: string;
  }>();

  // - page 2
  const [doses, setDoses] = useState<number>(0);
  const [scheduledDoses, setScheduledDoses] = useState<{
    morning: number | null;
    afternoon: number | null;
    evening: number | null;
    bedtime: number | null;
  }>({
    morning: null,
    afternoon: null,
    evening: null,
    bedtime: null,
  });
  const [asNeeded, setAsNeeded] = useState<boolean>(false);
  const [maxDosesPerDay, setMaxDosesPerDay] = useState<number>(0);
  const [takeWithFood, setTakeWithFood] = useState<boolean>(false);
  const [doctorsComments, setDoctorsComments] = useState<string>("");

  const [error, setError] = useState("");

  // mutations
  const upsertUserPrescription = useUpsertUserPrescription().mutateAsync;
  const upsertScheduledDoses = useUpsertScheduledDoses().mutateAsync;

  const validateForm = () => {
    if (!selectedDrugClass) {
      setError("select a drug.");
      return false;
    }

    // if (!selectedDispensableDrug) {
    //   setError("select a dosage.");
    //   return false;
    // }

    if (doses <= 0) {
      setError(
        `${getPluralDrugDoseDesc(
          `${selectedDrugClass?.DoseFormDesc || "doses"}`
        )} in a bottle empty.`
      );
      return false;
    }

    // If all the above conditions pass, clear any previous error.
    setError("");
    return true;
  };

  const resetForm = () => {
    setSelectedDrugClass(null);
    setSelectedDispensableDrug(undefined);
    setDoses(0);
    setScheduledDoses({
      morning: null,
      afternoon: null,
      evening: null,
      bedtime: null,
    });
    setAsNeeded(false);
    setTakeWithFood(false);
    setDoctorsComments("");
    setError("");
    setFormPage(1);
  };

  const resetScheduledDoses = () => {
    setScheduledDoses({
      morning: null,
      afternoon: null,
      evening: null,
      bedtime: null,
    });
  };

  const resetAsNeededDosage = () => {
    setAsNeeded(false);
    setMaxDosesPerDay(0);
  };

  async function submitForm({ addAnother }: { addAnother: boolean }) {
    // Validate the form, and if validation fails, return early.
    if (!validateForm()) {
      return;
    }

    // If validation passes, attempt to submit the form.
    if (authUser?.id && selectedDrugClass) {
      try {
        // 1 - create and upsert the user_prescription insert object
        const userPrescriptionInsert: UserPrescriptionInsert = {
          // drug info
          user_id: authUser.id,
          drug_name_id: selectedDrugClass.DrugNameID,
          drug_name_desc: selectedDrugClass.DrugNameDesc,
          dose_form_desc: selectedDrugClass.DoseFormDesc,
          dispensable_drug_id: selectedDispensableDrug?.dispensableDrugId,
          routed_dose_form_drug_id: selectedDrugClass.RoutedDoseFormDrugID,
          routed_dose_form_drug_desc: capitalizeFirstLetter(
            selectedDrugClass.RoutedDoseFormDrugDesc
          ),
          med_strength: selectedDispensableDrug?.medStrength,
          med_strength_unit: selectedDispensableDrug?.medStrengthUnit,
          take_with_food: takeWithFood,
          doctor_comments: doctorsComments,
          doses_fill_qty: doses,
          doses_remaining: doses,
        };

        const { data: prescriptionInsertRes, error: prescriptionInsertError } =
          await upsertUserPrescription(userPrescriptionInsert);

        // 2 - create and upsert the user_prescription_scheduled_dose insert objects
        const scheduledDoseInserts: UserPrescriptionScheduledDoseInsert[] = [];

        if (asNeeded) {
          // Create just one scheduled dose for as-needed medications
          const asNeededScheduledDoseInsert: UserPrescriptionScheduledDoseInsert =
            {
              user_id: authUser.id,
              user_prescription_id: prescriptionInsertRes[0]?.id,
              as_needed: true,
              max_doses_per_day: maxDosesPerDay,
            };
          scheduledDoseInserts.push(asNeededScheduledDoseInsert);
        } else {
          if (scheduledDoses.morning) {
            const morningScheduledDoseInsert: UserPrescriptionScheduledDoseInsert =
              {
                user_id: authUser.id,
                user_prescription_id: prescriptionInsertRes[0]?.id,
                dose_time: TimeOfDay.morning,
                dose_count: scheduledDoses.morning,
              };
            scheduledDoseInserts.push(morningScheduledDoseInsert);
          }
          if (scheduledDoses.afternoon) {
            const afternoonScheduledDoseInsert: UserPrescriptionScheduledDoseInsert =
              {
                user_id: authUser.id,
                user_prescription_id: prescriptionInsertRes[0]?.id,
                dose_time: TimeOfDay.afternoon,
                dose_count: scheduledDoses.afternoon,
              };
            scheduledDoseInserts.push(afternoonScheduledDoseInsert);
          }
          if (scheduledDoses.evening) {
            const eveningScheduledDoseInsert: UserPrescriptionScheduledDoseInsert =
              {
                user_id: authUser.id,
                user_prescription_id: prescriptionInsertRes[0]?.id,
                dose_time: TimeOfDay.evening,
                dose_count: scheduledDoses.evening,
              };
            scheduledDoseInserts.push(eveningScheduledDoseInsert);
          }
          if (scheduledDoses.bedtime) {
            const bedtimeScheduledDoseInsert: UserPrescriptionScheduledDoseInsert =
              {
                user_id: authUser.id,
                user_prescription_id: prescriptionInsertRes[0]?.id,
                dose_time: TimeOfDay.bedtime,
                dose_count: scheduledDoses.bedtime,
              };
            scheduledDoseInserts.push(bedtimeScheduledDoseInsert);
          }
        }

        const { data, error } = await upsertScheduledDoses(
          scheduledDoseInserts
        );

        if (error) {
          setError("Error inserting ");
        } else {
          // If successful, navigate to Nana's Day
          if (addAnother) {
            resetForm();
            navigate({
              path: CarePilotRoute.DAY_INTAKE_NEW,
            });
          } else {
            if (isMobile) {
              // right now, mboile has a different intake page then deskrop, need
              // to refactor so it's all the same
              navigate(
                {
                  path: CarePilotRoute.DAY_INTAKE,
                },
                { replace: true }
              );
            } else {
              navigateToCarespaceTab(CarespacePageTabs.MEDICATIONS, {
                replace: true,
              });
            }
          }
        }
      } catch {
        // Handle submission error here. You can set an error message if needed.
        setError("Failed to submit the user prescription. Please try again.");
      }
    }
  }

  const handleDone = (event: React.FormEvent) => {
    event.preventDefault();
    submitForm({ addAnother: false });
  };

  const handleSaveAndAddAnother = (event: React.FormEvent) => {
    event.preventDefault();
    submitForm({ addAnother: true });
  };

  return (
    <div className={`flex flex-col h-full gap-4 ${isMobile ? "px-6" : ""}`}>
      {/* back button */}
      {!isMobile ? (
        <>
          <ButtonWithIcon
            onClick={() => {
              navigateBack();
            }}
            disabled={false}
            text={"Back to Intake"}
            icon={IconOption.BACK_ARROW}
          />
          <PageMainHeader text="Add new prescription(s)" />
        </>
      ) : null}
      <div className="flex flex-col gap-4 max-w-[80ch] pt-4 pb-28">
        {/* form */}
        <form className="flex flex-col gap-4 max-w-[80ch]">
          {/* page 1 */}
          {formPage === 1 && (
            <>
              {/* page title */}
              <h3 className="text-xl">Step 1: Select medication</h3>
              {/* drug class & name */}
              <div className="flex flex-col gap-3">
                <label>Name</label>
                <DrugAutocomplete
                  onValueChange={setSelectedDrugClass}
                  disabled={false}
                />
              </div>
              {/* dosage */}
              <MedStrengthSelect
                drugClass={selectedDrugClass}
                onSelect={setSelectedDispensableDrug}
              />
              {/* description */}
              {/* <div className="flex flex-col gap-1">
                <label htmlFor="new_plan_entry_who">Description</label>
                <div className="flex w-full min-h-[150px] border border-faint-gray p-4">
                  {!!selectedDrugClass && isLoadingDrugInformation ? (
                    <div className="flex w-full h-full justify-center items-center">
                      <LoadingSpinner className="w-20 h-20" />
                    </div>
                  ) : !selectedDrugClass ? (
                    <div className="text-gray-400">{"N/A"}</div>
                  ) : (
                    <div>{drugInformation.description || "N/A"}</div>
                  )}
                </div>
              </div> */}
            </>
          )}
          {/* page 2 */}
          {formPage === 2 && (
            <div className="flex flex-col gap-10">
              {/* page title */}
              <h2 className="text-xl">Step 2: Enter prescription details</h2>

              {/* fill information section */}
              <div className="flex flex-col">
                <div className="grid grid-cols-[3fr,2fr]">
                  {/* text */}
                  <div>
                    How many{" "}
                    {getPluralDrugDoseDesc(
                      selectedDrugClass?.DoseFormDesc || "doses"
                    )}{" "}
                    are in a full bottle?
                  </div>
                  {/* input */}
                  <div className="flex-grow">
                    <TextField
                      label={capitalizeFirstLetter(
                        getPluralDrugDoseDesc(
                          selectedDrugClass?.DoseFormDesc || "doses"
                        )
                      )}
                      className="max-w-[15ch]"
                      value={doses.toString()}
                      type="number"
                      onKeyDown={(e) => {
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "-" ||
                          e.key === "+"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = Number(event.target.value);
                        if (value > 100) {
                          setDoses(100);
                        } else if (value < 0) {
                          setDoses(0);
                        } else {
                          setDoses(value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* use instructions */}
              <div className="flex flex-col">
                {/* when */}
                <div className="grid grid-cols-1 md:grid-cols-[3fr,2fr] gap-4 md:gap-0">
                  {/* label */}
                  <div>How should it be given? (check all that apply)</div>
                  {/* input */}
                  <div className="flex flex-col gap-6 mx-auto md:mx-0">
                    {/* morning */}
                    <div className="grid grid-cols-[15ch,auto] items-center">
                      <DefaultCheckbox
                        checked={scheduledDoses.morning != null}
                        disabled={false}
                        onChange={(isChecked) => {
                          setScheduledDoses({
                            ...scheduledDoses,
                            morning: isChecked
                              ? scheduledDoses.morning != null &&
                                scheduledDoses.morning >= 0
                                ? scheduledDoses.morning
                                : 0
                              : null,
                          });
                          if (isChecked) resetAsNeededDosage();
                        }}
                        label="Morning"
                      />
                      <TextField
                        label={capitalizeFirstLetter(
                          getPluralDrugDoseDesc(
                            selectedDrugClass?.DoseFormDesc || "doses"
                          )
                        )}
                        className="max-w-[15ch] text-sm"
                        value={scheduledDoses.morning?.toString() || 0}
                        type="number"
                        onKeyDown={(e) => {
                          if (
                            e.key === "e" ||
                            e.key === "E" ||
                            e.key === "-" ||
                            e.key === "+"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          if (value > 100) {
                            setScheduledDoses({
                              ...scheduledDoses,
                              morning: 100,
                            });
                          } else if (value < 0) {
                            setScheduledDoses({
                              ...scheduledDoses,
                              morning: 0,
                            });
                          } else {
                            setScheduledDoses({
                              ...scheduledDoses,
                              morning: value,
                            });
                          }
                        }}
                      />
                    </div>
                    {/* afternoon */}
                    <div className="grid grid-cols-[15ch,auto] items-center">
                      <DefaultCheckbox
                        checked={scheduledDoses.afternoon != null}
                        disabled={false}
                        onChange={(isChecked) => {
                          setScheduledDoses({
                            ...scheduledDoses,
                            afternoon: isChecked
                              ? scheduledDoses.afternoon != null &&
                                scheduledDoses.afternoon >= 0
                                ? scheduledDoses.afternoon
                                : 0
                              : null,
                          });
                          if (isChecked) resetAsNeededDosage();
                        }}
                        label="Afternoon"
                      />
                      <TextField
                        label={capitalizeFirstLetter(
                          getPluralDrugDoseDesc(
                            selectedDrugClass?.DoseFormDesc || "doses"
                          )
                        )}
                        className="max-w-[15ch] text-sm"
                        value={scheduledDoses.afternoon?.toString() || 0}
                        type="number"
                        onKeyDown={(e) => {
                          if (
                            e.key === "e" ||
                            e.key === "E" ||
                            e.key === "-" ||
                            e.key === "+"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          if (value > 100) {
                            setScheduledDoses({
                              ...scheduledDoses,
                              afternoon: 100,
                            });
                          } else if (value < 0) {
                            setScheduledDoses({
                              ...scheduledDoses,
                              afternoon: 0,
                            });
                          } else {
                            setScheduledDoses({
                              ...scheduledDoses,
                              afternoon: value,
                            });
                          }
                        }}
                      />
                    </div>
                    {/* evening */}
                    <div className="grid grid-cols-[15ch,auto] items-center">
                      <DefaultCheckbox
                        checked={scheduledDoses.evening != null}
                        disabled={false}
                        onChange={(isChecked) => {
                          setScheduledDoses({
                            ...scheduledDoses,
                            evening: isChecked
                              ? scheduledDoses.evening != null &&
                                scheduledDoses.evening >= 0
                                ? scheduledDoses.evening
                                : 0
                              : null,
                          });
                          if (isChecked) resetAsNeededDosage();
                        }}
                        label="Evening"
                      />
                      <TextField
                        label={capitalizeFirstLetter(
                          getPluralDrugDoseDesc(
                            selectedDrugClass?.DoseFormDesc || "doses"
                          )
                        )}
                        className="max-w-[15ch] text-sm"
                        value={scheduledDoses.evening?.toString() || 0}
                        type="number"
                        onKeyDown={(e) => {
                          if (
                            e.key === "e" ||
                            e.key === "E" ||
                            e.key === "-" ||
                            e.key === "+"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          if (value > 100) {
                            setScheduledDoses({
                              ...scheduledDoses,
                              evening: 100,
                            });
                          } else if (value < 0) {
                            setScheduledDoses({
                              ...scheduledDoses,
                              evening: 0,
                            });
                          } else {
                            setScheduledDoses({
                              ...scheduledDoses,
                              evening: value,
                            });
                          }
                        }}
                      />
                    </div>
                    {/* bedtime */}
                    <div className="grid grid-cols-[15ch,auto] items-center">
                      <DefaultCheckbox
                        checked={scheduledDoses.bedtime != null}
                        disabled={false}
                        onChange={(isChecked) => {
                          setScheduledDoses({
                            ...scheduledDoses,
                            bedtime: isChecked
                              ? scheduledDoses.bedtime != null &&
                                scheduledDoses.bedtime >= 0
                                ? scheduledDoses.bedtime
                                : 0
                              : null,
                          });
                          if (isChecked) resetAsNeededDosage();
                        }}
                        label="Bedtime"
                      />
                      <TextField
                        label={capitalizeFirstLetter(
                          getPluralDrugDoseDesc(
                            selectedDrugClass?.DoseFormDesc || "doses"
                          )
                        )}
                        className="max-w-[15ch] text-sm"
                        value={scheduledDoses.bedtime?.toString() || 0}
                        type="number"
                        onKeyDown={(e) => {
                          if (
                            e.key === "e" ||
                            e.key === "E" ||
                            e.key === "-" ||
                            e.key === "+"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          if (value > 100) {
                            setScheduledDoses({
                              ...scheduledDoses,
                              bedtime: 100,
                            });
                          } else if (value < 0) {
                            setScheduledDoses({
                              ...scheduledDoses,
                              bedtime: 0,
                            });
                          } else {
                            setScheduledDoses({
                              ...scheduledDoses,
                              bedtime: value,
                            });
                          }
                        }}
                      />
                    </div>
                    {/* as needed */}
                    <span className="mx-auto">-OR-</span>
                    {/* as needed checkbox */}
                    <div className="grid grid-cols-[15ch,auto] items-center pb-3">
                      <DefaultCheckbox
                        checked={asNeeded}
                        disabled={false}
                        onChange={(isChecked) => {
                          setAsNeeded(isChecked);
                          if (isChecked) resetScheduledDoses();
                        }}
                        label="As Needed"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* max doses per day */}
              <div className="grid grid-cols-[3fr,2fr] items-center">
                <span>Max doses per day?</span>
                <TextField
                  label={`Max ${capitalizeFirstLetter(
                    getPluralDrugDoseDesc(
                      selectedDrugClass?.DoseFormDesc || "doses"
                    )
                  )}`}
                  className="max-w-[15ch] text-sm"
                  value={maxDosesPerDay.toString()}
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "-" ||
                      e.key === "+"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    setMaxDosesPerDay(Number(event.target.value));
                  }}
                />
              </div>

              {/* with food? */}
              <div className="grid grid-cols-[3fr,2fr]">
                {/* text */}
                <div>Take with food?</div>
                {/* input */}
                <RadioButtonsGroup
                  id="take_with_food_checkbox"
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                  value={takeWithFood ? "yes" : "no"}
                  onChange={(selectedOption) => {
                    setTakeWithFood(selectedOption === "yes");
                  }}
                />
              </div>

              {/* doctors comments */}
              <div className="flex flex-col gap-3">
                {/* text */}
                <div>Doctor’s Comments</div>
                {/* input */}
                <TextArea
                  className="min-h-[150px]"
                  data-hj-allow
                  value={doctorsComments}
                  onChange={(event) => {
                    setDoctorsComments(event.target.value);
                  }}
                  placeholder="Type doctor's comments..."
                />
              </div>
            </div>
          )}
        </form>
        {/* buttons row */}
        <div className="flex w-full justify-end gap-4 pt-14">
          {isMobile ? (
            <FooterButtons>
              {formPage === 1 && (
                <>
                  <ButtonWithIcon
                    text="Cancel"
                    icon={IconOption.CANCEL}
                    onClick={(e: any) => {
                      // clear?
                      e.preventDefault();
                      navigateBack();
                    }}
                  />
                  <ButtonWithIcon
                    text="Next"
                    icon={IconOption.ARROW}
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (!selectedDrugClass) setError("no selected drug.");
                      else {
                        setError("");
                        setFormPage(2);
                      }
                    }}
                  />
                </>
              )}
              {formPage === 2 && (
                <>
                  <ButtonWithIcon
                    text="Back"
                    icon={IconOption.BACK_ARROW}
                    onClick={() => {
                      resetForm();
                      setFormPage(1);
                    }}
                  />
                  <ButtonWithIcon
                    text="Done"
                    icon={IconOption.CHECKMARK}
                    onClick={handleDone}
                  />
                  <ButtonWithIcon
                    text="Add another"
                    icon={IconOption.PLUS}
                    onClick={handleSaveAndAddAnother}
                  />
                </>
              )}
            </FooterButtons>
          ) : (
            <>
              {formPage === 1 && (
                <>
                  <ButtonWithIcon
                    text="Cancel"
                    icon={IconOption.CANCEL}
                    onClick={(e: any) => {
                      // clear?
                      e.preventDefault();
                      navigateBack();
                    }}
                  />
                  <ButtonWithIcon
                    text="Next"
                    icon={IconOption.ARROW}
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (!selectedDrugClass) setError("no selected drug.");
                      else {
                        setError("");
                        setFormPage(2);
                      }
                    }}
                  />
                </>
              )}
              {formPage === 2 && (
                <>
                  <ButtonWithIcon
                    text="Back"
                    icon={IconOption.BACK_ARROW}
                    onClick={() => {
                      resetForm();
                      setFormPage(1);
                    }}
                  />
                  <ButtonWithIcon
                    text="Done"
                    icon={IconOption.CHECKMARK}
                    onClick={handleDone}
                  />
                  <ButtonWithIcon
                    text="Save and add another"
                    icon={IconOption.PLUS}
                    onClick={handleSaveAndAddAnother}
                    size="small"
                  />
                </>
              )}
            </>
          )}
        </div>
        {/* error message row — @TODO: per element error messaging using something like react-hook-form */}
        {error && (
          <div className="flex w-full justify-end gap-2 text-red-700">
            Error: {error}
          </div>
        )}
      </div>
    </div>
  );
}

export function MedStrengthSelect({
  drugClass,
  onSelect,
}: {
  drugClass?: DrugClass | null;
  onSelect: (val: {
    dispensableDrugId: string;
    medStrength: string;
    medStrengthUnit: string;
  }) => void;
}) {
  const [selectedMedStrength, setSelectedMedStrength] = useState<string>();

  const medStrengthOptions = drugClass?.DispensableDrugs.map(
    (val: DispensableDrug) => ({
      label: val.MedStrength ? val.MedStrength : "N/A",
      value: val.MedStrength ? val.MedStrength : "N/A",
    })
  );

  const uniqueMedStrengthOptions: {
    label: string;
    value: string;
  }[] = Array.from(
    new Map(
      medStrengthOptions?.map((option) => [option.value, option])
    ).values()
  ).sort((a, b) => Number(a.value) - Number(b.value));

  const medStrengthUnit = drugClass?.DispensableDrugs[0].MedStrengthUnit
    ? drugClass?.DispensableDrugs[0].MedStrengthUnit
    : "N/A";

  const getMatchingDispensableDrug = () => {
    return drugClass?.DispensableDrugs.find(
      (option) => option.MedStrength === selectedMedStrength
    );
  };

  useEffect(() => {
    if (drugClass) {
      const dispensableDrugId =
        drugClass?.DispensableDrugs[0].DispensableDrugID;

      const medStrength = drugClass?.DispensableDrugs[0].MedStrength
        ? drugClass?.DispensableDrugs[0].MedStrength
        : "N/A";
      const medStrengthUnit = drugClass?.DispensableDrugs[0].MedStrengthUnit
        ? drugClass?.DispensableDrugs[0].MedStrengthUnit
        : "N/A";

      onSelect({
        dispensableDrugId,
        medStrength,
        medStrengthUnit,
      });
    }
  }, [drugClass]);

  // if the user has selected a drug class, show populated Select
  if (uniqueMedStrengthOptions) {
    return (
      <div className="flex flex-col gap-1 max-w-fit">
        <label>Dosage strength</label>
        <div className="flex gap-2 items-center">
          <div className="min-w-[10ch]">
            <Select
              classNames="w-full"
              borderClass="border-zinc-300"
              onChange={(selectedVal) => {
                setSelectedMedStrength(selectedVal);
                onSelect({
                  dispensableDrugId:
                    getMatchingDispensableDrug()?.DispensableDrugID ||
                    drugClass?.DispensableDrugs[0].DispensableDrugID ||
                    "N/A",
                  medStrength: selectedVal,
                  medStrengthUnit,
                });
              }}
              disabled={uniqueMedStrengthOptions.length === 0}
              options={uniqueMedStrengthOptions}
              currentOption={
                uniqueMedStrengthOptions.find(
                  (option) => option.value === selectedMedStrength
                ) || uniqueMedStrengthOptions[0]
              }
            />
          </div>
          {/* <div>{drugClass?.DispensableDrugs[0]?.MedStrengthUnit || "N/A"}</div> */}
        </div>
      </div>
    );
  }
  // else, show disabled
  else {
    return (
      <div className="flex flex-col gap-1 max-w-fit">
        <span>Dosage strength</span>
        <Select
          onChange={(item) => {
            // noop, perma disabled
          }}
          disabled={true}
          options={[{ label: "N/A", value: "N/A" }]}
          currentOption={{ label: "N/A", value: "N/A" }}
        />
      </div>
    );
  }
}
