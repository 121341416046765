import { YellowPagesListing } from "backend/functions";
import { useUpsertServiceResourceAndPlanEntry } from "backend/resources/services/serviceResource";
import BackButton from "components/BackButton/BackButton";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { LocalServicesSearch } from "components/ResourcesPage/components/LocalServicesSearch";
import { CareCentralRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useState } from "react";
import { useActiveOrganizationId } from "state/organization/organization";

export default function AddNewProvider() {
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();

  // state
  const [selectedResources, setSelectedResources] = useState<
    YellowPagesListing[]
  >([]);
  // Mutations
  const upsertServiceResource =
    useUpsertServiceResourceAndPlanEntry().mutateAsync;
  // Queries
  const activeOrgId = useActiveOrganizationId();

  const handleSave = async () => {
    if (selectedResources) {
      const promises = selectedResources.map((selectedResource) =>
        upsertServiceResource({
          serviceResourceUpdate: {
            ...selectedResource,
            organization_id: activeOrgId,
          },
        })
      );
      await Promise.all(promises);
    }
    navigateBack();
  };

  return (
    <PageContainer>
      <BackButton className="mb-4" />
      <div className="h-auto max-h-screen flex flex-col gap-5">
        <PageMainHeader text="Add New Provider" />
        <ButtonWithIcon
          onClick={() =>
            navigate({ path: CareCentralRoute.ADD_NEW_PROVIDER_MANUALLY })
          }
          text={"Add Manually"}
          size={"small"}
          icon={IconOption.PLUS}
        />
        <LocalServicesSearch
          selectedLocalResources={selectedResources}
          onSelectLocalResource={(resource) => {
            if (resource) {
              if (
                selectedResources.some(
                  (r) => r.listing_id === resource.listing_id
                )
              )
                setSelectedResources(
                  selectedResources.filter(
                    (r) => r.listing_id !== resource.listing_id
                  )
                );
              else setSelectedResources([...selectedResources, resource]);
            }
          }}
          backgroundColor="bg-gray-100/80"
          isCheckBoxSquare
        />
        <div className="flex bg-white justify-end gap-5 py-2 max-w-3xl">
          <ButtonWithIcon
            onClick={navigateBack}
            text={"Cancel"}
            size={"small"}
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={handleSave}
            text={"Save"}
            size={"small"}
            icon={IconOption.CHECKMARK}
          />
        </div>
      </div>
    </PageContainer>
  );
}
