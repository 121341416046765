import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import startCase from "lodash/startCase";
import { useMemo } from "react";

import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { useUserStore } from "state/user";

import { Accordion } from "shared/ui/accordion";
import { Avatar } from "shared/ui/avatar";
import { Badge } from "shared/ui/badge";
import { DataTable } from "shared/ui/data-table";
import { Text } from "shared/ui/text";

import { TaskStatus } from "../constants";
import type { Task } from "../types";

const columnHelper = createColumnHelper<Task>();

export interface ExpandableTablesProps {
  groups: {
    value: string;
    label: string;
    tasks: Task[];
  }[];
}

export default function ExpandableTables({ groups }: ExpandableTablesProps) {
  const navigate = useAppNavigate();
  const authUser = useUserStore((state) => state.user);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Care Strategies",
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (ctx) => {
          const value = ctx.getValue();

          return (
            <Badge
              color={
                value === TaskStatus.Done
                  ? "green"
                  : value === TaskStatus.InProgress
                  ? "purple"
                  : value === TaskStatus.NotStarted
                  ? "blue"
                  : "gray"
              }>
              {startCase(value)}
            </Badge>
          );
        },
      }),
      columnHelper.accessor("scheduled_date_time", {
        header: "Date",
        cell: (ctx) => {
          const value = ctx.getValue();

          return value ? format(new Date(value), "MM/dd/yyyy") : "N/A";
        },
      }),
      columnHelper.accessor(
        (task) => `${task.user?.first_name} ${task.user?.last_name}`,
        {
          id: "who",
          header: "Who",
          cell: (ctx) => (
            <div className="flex flex-row items-center gap-2">
              <Avatar
                firstName={ctx.row.original.user?.first_name || ""}
                lastName={ctx.row.original.user?.last_name || ""}
                profileImage={ctx.row.original.user?.profile_image || ""}
              />
              <Text>
                {authUser?.id === ctx.row.original.user?.id
                  ? "Me"
                  : ctx.getValue()}
              </Text>
            </div>
          ),
        }
      ),
    ],
    [authUser?.id]
  );

  return (
    <Accordion
      items={groups.map((group) => ({
        ...group,
        label: (
          <Text
            size="lg"
            className="text-left">
            {group.label}
          </Text>
        ),
        content: (
          <DataTable
            columns={columns}
            data={group.tasks}
            onRowClick={(row) =>
              navigate({
                path: CareCentralRoute.VIEW_TASK,
                params: {
                  id: row.id,
                },
              })
            }
          />
        ),
      }))}
      type="multiple"
    />
  );
}
