import { zodResolver } from "@hookform/resolvers/zod";
import { outlinedInputClasses } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { legalSexOptions } from "components/CarePilotOnboarding/components/BasicInformationModal";
import { RadioButtonsGroup } from "components/RadioGroup";
import dayjs from "dayjs";
import { useWindowSize } from "hooks/useWindowSize";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { MultiPageFormProps } from "shared/forms/types";
import { Form, FormControl, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shared/ui/select";
import { Title } from "shared/ui/title";
import * as z from "zod";

export enum DyadTier {
  Low = "Low",
  Moderate = "Moderate",
  High = "High",
  NA = "N/A",
}

export const carespaceInformationFormSchema = z.object({
  carespaceName: z.string().min(2, {
    message: "Carespace name must be at least 2 characters.",
  }),
  patientFirstName: z.string(),
  patientLastName: z.string(),
  patientBirthday: z.date(),
  patientLegalSex: z.string(),
  dyad: z.enum(Object.values(DyadTier) as [DyadTier, ...DyadTier[]]),
  hasCaregiver: z.boolean().nullable(),
});

export const useCarespaceInformationForm = (
  defaultValues?: z.infer<typeof carespaceInformationFormSchema>
) =>
  useForm<z.infer<typeof carespaceInformationFormSchema>>({
    resolver: zodResolver(carespaceInformationFormSchema),
    defaultValues: defaultValues ?? {},
    mode: "onSubmit",
  });

export type CarespaceInformationFormHook = UseFormReturn<
  z.infer<typeof carespaceInformationFormSchema>
>;

export const carespaceInformationFormId = "carespace-information-form";

export function CarespaceInformationForm(
  props: MultiPageFormProps<z.infer<typeof carespaceInformationFormSchema>> & {
    isEditing?: boolean;
  }
) {
  const { form, isEditing } = props;
  const { isMobile } = useWindowSize();
  const formFields = form.watch();

  function handlePatientNameChange(
    fieldName: keyof z.infer<typeof carespaceInformationFormSchema>,
    fieldValue: string
  ) {
    form.setValue(fieldName, fieldValue);

    const firstName =
      fieldName === "patientFirstName"
        ? fieldValue
        : formFields.patientFirstName ?? "";
    const lastName =
      fieldName === "patientLastName"
        ? fieldValue
        : formFields.patientLastName ?? "";

    form.setValue("carespaceName", `${firstName} ${lastName}`);
  }

  return (
    <Form
      form={form}
      id={carespaceInformationFormId}>
      <Title order={3}>
        {isEditing
          ? "Edit carespace name and patient info"
          : "Step 1: Add carespace name and patient info"}
      </Title>
      <p className="-mt-2">Patient information</p>
      <div className="grid grid-cols-[2fr,3fr] gap-2 -mt-2">
        <FormField
          control={form.control}
          name="patientFirstName"
          label="First"
          render={({ field }) => (
            <Input
              {...field}
              onChange={(e) =>
                handlePatientNameChange(field.name, e.target.value)
              }
            />
          )}
        />
        <FormField
          control={form.control}
          name="patientLastName"
          label="Last"
          render={({ field }) => (
            <Input
              {...field}
              onChange={(e) =>
                handlePatientNameChange(field.name, e.target.value)
              }
            />
          )}
        />
      </div>
      <div className="grid grid-cols-[2fr,3fr] gap-2">
        <FormField
          control={form.control}
          name="patientBirthday"
          label="DOB"
          render={({ field }) => (
            <DatePicker
              value={dayjs(field.value)}
              onChange={(date) => field.onChange(date?.toDate())}
              disableOpenPicker={!isMobile}
              slotProps={{
                field: {
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  },
                },
                textField: {
                  sx: {
                    fieldset: {
                      borderRadius: 2,
                      borderColor: "#E4E4E7",
                    },
                    [`.${outlinedInputClasses.root}`]: {
                      height: 40,
                      width: 165,
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    },
                  },
                },
              }}
            />
          )}
        />
        <FormField
          control={form.control}
          name="patientLegalSex"
          label="Legal sex"
          render={({ field }) => (
            <Select
              onValueChange={field.onChange}
              defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {Object.values(legalSexOptions).map((option) => (
                  <SelectItem value={option.value}>{option.label}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
      </div>
      <p className="mt-2">Carespace information</p>
      <FormField
        control={form.control}
        name="carespaceName"
        label="Carespace Name"
        render={({ field }) => <Input {...field} />}
      />
      <div className="flex flex-col gap-4 mt-2">
        <p className="text-sm text-severe-red">
          Note: Changing the Tier or caregiver status will effect the scheduling
          of certain tasks per the GUIDE requirements.
        </p>
        <FormField
          control={form.control}
          name="dyad"
          label="DYAD Tier (if known)"
          render={({ field }) => (
            <RadioButtonsGroup
              id={"dyad"}
              options={Object.values(DyadTier).map((value) => ({
                value,
                label: value,
              }))}
              {...field}
              value={field.value === null ? "N/A" : field.value}
              row></RadioButtonsGroup>
          )}
        />
        <FormField
          control={form.control}
          name="hasCaregiver"
          label="Has a Caregiver (if known)"
          render={({ field }) => (
            <RadioButtonsGroup
              id={"hasCaregiver"}
              options={[
                { value: false, label: "No" },
                { value: true, label: "Yes" },
                { value: "N/A", label: "N/A" },
              ]}
              onChange={(value) =>
                field.onChange(value === "N/A" ? null : value === "true")
              }
              value={field.value === null ? "N/A" : field.value}
              row></RadioButtonsGroup>
          )}
        />
      </div>
    </Form>
  );
}
