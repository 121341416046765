import { format, setHours, setMinutes, setSeconds } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useConversationDocumentsByConversationId } from "backend/resources/conversation_document/conversation_document";
import { useNetworkName } from "backend/resources/network/network";
import type { TaskStatus } from "backend/resources/planEntry";
import {
  PlaceTypeSchema,
  useAddConversationToPlanEntry,
  usePlanEntryWithGuideTask,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";

import BackButton from "components/BackButton/BackButton";
import { LoadingSpinner } from "components/LoadingSpinner";
import AddAttendeesButtonPopup from "components/MyPlanPage/components/AddAttendeesButtonPopup";
import VideoCallButton from "components/MyPlanPage/components/VideoCallButton";
import Who, { UserType } from "components/MyPlanPage/components/Who";
import { PageContainer } from "components/PageContainer";
import { HeaderNamePill } from "components/Pill";
import { GoogleAutocomplete } from "components/PlacesAutocomplete";
import DocumentsUploadSection from "components/ServicePage/components/DocumentsSection";
import { GuideCategoryColorStyle } from "components/TaskNavigatorPage/taskTableUtils";

import { Combobox } from "shared/ui/combobox";
import { DatePicker } from "shared/ui/date-picker";
import { Input } from "shared/ui/input";
import { LabeledContent } from "shared/ui/labeled-content";
import { Text } from "shared/ui/text";
import { Textarea } from "shared/ui/textarea";
import { Title } from "shared/ui/title";

import CallManagement from "./components/call-management";
import TaskManagement from "./components/task-management";

import {
  GUIDE_IDENTIFIER_FOR_RECURRING_CALLS,
  RECURRING_INTERVAL_OPTIONS,
  STATUS_OPTIONS,
} from "./constants";

export default function ViewTask() {
  const { id } = useParams<{ id: string }>();

  const [notes, setNotes] = useState<string | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const { data: entryData, isLoading: isLoadingEntryData } =
    usePlanEntryWithGuideTask(id);
  const { networkName, isLoading: isLoadingNetworkName } = useNetworkName(
    entryData?.network_id ?? undefined
  );
  const { data: documents, isLoading: isDocumentsLoading } =
    useConversationDocumentsByConversationId(
      entryData?.conversation_id ?? undefined
    );

  const isGuideCall =
    entryData?.guide_task?.GUIDE_identifier ===
      GUIDE_IDENTIFIER_FOR_RECURRING_CALLS ||
    entryData?.mah_guide_task?.guide_reference_id ===
      GUIDE_IDENTIFIER_FOR_RECURRING_CALLS;

  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;
  const addConversationToPlanEntry =
    useAddConversationToPlanEntry().mutateAsync;

  useEffect(() => {
    if (entryData && initialLoad) {
      setNotes(entryData.notes ?? "");
      setInitialLoad(false);
      if (!entryData.conversation_id) {
        addConversationToPlanEntry({
          taskId: entryData.id,
          isExternal: false,
          external_participant_id: entryData.user_id,
          networkId: entryData.network_id || "",
        });
      }
    }
  }, [addConversationToPlanEntry, entryData, initialLoad]);

  if (isLoadingNetworkName || isLoadingEntryData || isDocumentsLoading) {
    return <LoadingSpinner />;
  }

  if (!entryData) {
    return <div>Task data not found.</div>;
  }

  return (
    <PageContainer>
      <div className="flex flex-col gap-4 mx-5 max-w-3xl">
        <BackButton />
        <div className="flex gap-4 w-full">
          <HeaderNamePill
            text={networkName}
            color="#176590"
          />
          <HeaderNamePill
            text={entryData?.guide_task?.guide_category?.title}
            color={
              GuideCategoryColorStyle[
                entryData.guide_task?.guide_category?.title ?? ""
              ]
            }
          />
        </div>

        <div className="w-full text-center md:text-left">
          <Title>{entryData.name}</Title>
        </div>

        {entryData.guide_task?.description && (
          <div className="flex flex-col gap-1">
            <Text>Description</Text>
            <Text size="sm">{entryData.guide_task?.description}</Text>
          </div>
        )}

        <div className="grid grid-cols-3 gap-4 w-[800px]">
          <LabeledContent label="Status">
            <Combobox
              options={STATUS_OPTIONS}
              defaultValue={entryData.status}
              onChange={(value) => {
                if (value) {
                  updatePlanEntry({
                    ...entryData,
                    status: value as TaskStatus,
                  });
                }
              }}
            />
          </LabeledContent>

          <Who
            userType={UserType.ORG_USERS}
            isEditing
            networkId={entryData.network_id}
            entry={entryData}
            selectedPlanEntryOwnerId={entryData.user_id}
            setPlanEntryOwner={(planEntryOwner: string) =>
              updatePlanEntry({ ...entryData, user_id: planEntryOwner })
            }
            label="Assigned To"
          />

          <LabeledContent label="Recurring Interval">
            <Combobox
              options={RECURRING_INTERVAL_OPTIONS}
              onChange={(value) => {
                if (value)
                  updatePlanEntry({ ...entryData, recurring_interval: value });
              }}
              defaultValue={entryData.recurring_interval as string}
              disabled={isGuideCall}
            />
          </LabeledContent>

          <LabeledContent label="Due">
            <DatePicker
              onChange={(value) => {
                if (value)
                  updatePlanEntry({
                    ...entryData,
                    scheduled_date_time: value.toISOString(),
                  });
              }}
              value={
                entryData?.scheduled_date_time
                  ? dayjs(entryData?.scheduled_date_time).toDate()
                  : new Date()
              }
            />
          </LabeledContent>

          {isGuideCall && (
            <>
              <LabeledContent label="Time">
                <Input
                  type="time"
                  defaultValue={format(
                    entryData.scheduled_date_time || "",
                    "kk:mm:ss"
                  )}
                  onBlur={(e) => {
                    const time = e.target.value;
                    const [hours, minutes, seconds] = time.split(":");
                    const newDate = new Date(
                      entryData.scheduled_date_time || ""
                    );
                    const withHours = setHours(
                      newDate,
                      Number.parseInt(hours, 10)
                    );
                    const withMinutes = setMinutes(
                      withHours,
                      Number.parseInt(minutes, 10)
                    );
                    const date = seconds
                      ? setSeconds(withMinutes, Number.parseInt(seconds, 10))
                      : withMinutes;

                    updatePlanEntry({
                      ...entryData,
                      scheduled_date_time: date.toISOString(),
                    });
                  }}
                />
              </LabeledContent>

              <LabeledContent label="Location">
                <GoogleAutocomplete
                  height="small"
                  paddingTop="small"
                  font="small"
                  initialValue={
                    entryData?.location
                      ? PlaceTypeSchema.parse(entryData?.location)
                      : null
                  }
                  onValueChange={(value) => {
                    updatePlanEntry({
                      ...entryData,
                      location: JSON.parse(JSON.stringify(value)),
                    });
                  }}
                />
              </LabeledContent>

              <AddAttendeesButtonPopup
                editOptions={{
                  setAttendees: (attendees: string[]) =>
                    updatePlanEntry({
                      ...entryData,
                      plan_entry_attendee: attendees.map((attendee) => ({
                        user_id: attendee,
                      })),
                    }),
                }}
                initialAttendeeIds={
                  entryData?.plan_entry_attendee?.map(
                    (attendee) => attendee.user_id
                  ) ?? []
                }
              />

              <VideoCallButton
                googleMeetingCode={entryData?.google_meeting_code}
                setGoogleMeetingCode={(code: string) =>
                  updatePlanEntry({ ...entryData, google_meeting_code: code })
                }
              />
            </>
          )}
        </div>

        <LabeledContent label="Notes">
          <Textarea
            onChange={async (value) => {
              if (value) {
                setNotes(value.target.value);
                await updatePlanEntry({
                  ...entryData,
                  notes: value.target.value,
                });
              }
            }}
            value={notes ?? ""}
          />
        </LabeledContent>

        <DocumentsUploadSection
          documents={documents ?? []}
          networkId={entryData.network_id || ""}
          conversationId={entryData.conversation_id}
          titleSize="md"
          isExternal={false}
        />

        {isGuideCall ? (
          <CallManagement planEntryId={id} />
        ) : (
          <TaskManagement id={id} />
        )}
      </div>
    </PageContainer>
  );
}
