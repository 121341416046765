import { Factor } from "@supabase/supabase-js";
import { supabase } from "clients/supabaseClient";
import { SharedRoute } from "lib/routing";
import { useCallback, useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";

type AALLevel = "aal1" | "aal2";

const useMFA = () => {
  const randomId = useId();
  const randomNumber = Math.floor(Math.random() * 1000);
  const [currentLevel, setCurrentLevel] = useState<string | null>(null);
  const [nextLevel, setNextLevel] = useState<string | null>(null);
  const [shouldImplementMFA, setShouldImplementMFA] = useState<boolean>(false);
  const [shouldVerifyMFA, setShouldVerifyMFA] = useState<boolean | null>(null);
  const [shouldEnrollMFA, setShouldEnrollMFA] = useState<boolean | null>(null);
  const [currentFactorId, setCurrentFactorId] = useState<string>();
  const [challengeId, setChallengeId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [aalData, setAalData] = useState<any | null>(null);
  const [factorsList, setFactorsList] = useState<Factor[] | null>([]);
  const [factorsRetrieved, setFactorsRetrieved] = useState<boolean>(false);

  const navigate = useNavigate();

  const shouldEnrollToMFA = (
    currentLevel?: AALLevel,
    nextLevel?: AALLevel
  ): boolean => currentLevel === "aal1" && nextLevel === "aal1";

  const shouldVerifyToMFA = (
    currentLevel?: AALLevel,
    nextLevel?: AALLevel
  ): boolean => currentLevel === "aal1" && nextLevel === "aal2";

  const shouldRedirectToMFA = (
    currentLevel?: AALLevel,
    nextLevel?: AALLevel
  ): boolean =>
    shouldEnrollToMFA(currentLevel, nextLevel) ||
    shouldVerifyToMFA(currentLevel, nextLevel);

  // Fetch the current and next levels of authentication assurance
  const getAAL = useCallback(async () => {
    try {
      const { data, error } =
        await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
      if (error) throw error;
      setCurrentLevel(data.currentLevel);
      setNextLevel(data.nextLevel);
      setAalData(data);

      // Determine if MFA should be implemented (when nextLevel is higher than currentLevel)
      if (data.currentLevel && data.nextLevel) {
        setShouldImplementMFA(
          shouldRedirectToMFA(
            data.currentLevel as AALLevel,
            data.nextLevel as AALLevel
          )
        );
        setShouldEnrollMFA(
          shouldEnrollToMFA(
            data.currentLevel as AALLevel,
            data.nextLevel as AALLevel
          )
        );
        setShouldVerifyMFA(
          shouldVerifyToMFA(
            data.currentLevel as AALLevel,
            data.nextLevel as AALLevel
          )
        );
      }
    } catch (err: any) {
      setError(err.message);
    }
  }, []);

  // List available MFA factors for the user
  const listFactors = useCallback(async () => {
    try {
      const { data, error } = await supabase.auth.mfa.listFactors();
      if (error) throw error;
      setFactorsList(data.all);
      setFactorsRetrieved(true);
    } catch (err: any) {
      setError(err.message);
      return null;
    }
  }, []);

  const getFactorByType = (factorType: string) => {
    return factorsList?.find(
      (factor: Factor) => factor.factor_type === factorType
    );
  };

  // Enroll a new MFA factor and retrieve the QR code
  const enrollTOTPFactor = useCallback(async () => {
    try {
      const { data, error } = await supabase.auth.mfa.enroll({
        factorType: "totp",
        friendlyName: `toptp-${randomId}-${randomNumber}`,
      });
      if (error) throw error;
      setCurrentFactorId(data.id);
      setError(error);
      return { factorId: data.id, qrCode: data.totp.qr_code };
    } catch (err: any) {
      setError(err.message);
      return null;
    } finally {
      setError(null);
    }
  }, []);

  const enrollPhoneFactor = useCallback(async (phoneNumber: string) => {
    try {
      const { data, error } = await supabase.auth.mfa.enroll({
        factorType: "phone",
        friendlyName: `phone-${randomId}-${randomNumber}`,
        phone: phoneNumber,
      });
      if (error) throw error;
      setCurrentFactorId(data.id);
      setError(error);
      return data.id;
    } catch (err: any) {
      setError(err.message);
      return null;
    } finally {
      setError(null);
    }
  }, []);

  const unenrollFactor = useCallback(
    async (factorId: string) => {
      try {
        if (!factorId)
          throw new Error("No factor ID available for unenrollment.");

        const { error } = await supabase.auth.mfa.unenroll({
          factorId,
        });

        await getAAL();
        if (error) throw error;

        return true;
      } catch (err: any) {
        setError(err.message);
        return false;
      }
    },
    [currentFactorId]
  );

  const executeChallenge = useCallback(async (factorId: string) => {
    try {
      if (!factorId) throw new Error("No factor ID available for challenge.");

      const { data, error } = await supabase.auth.mfa.challenge({
        factorId,
      });
      if (error) throw error;

      setChallengeId(data.id);
      return data.id;
    } catch (err: any) {
      setError(err.message);
    }
  }, []);

  const verifyChallenge = useCallback(
    async (factorId: string, challengeId: string, code: string) => {
      try {
        if (!factorId || !challengeId || !code)
          throw new Error(
            "No factor or challenge ID available for verification."
          );

        const { data, error } = await supabase.auth.mfa.verify({
          factorId,
          challengeId,
          code,
        });
        if (error) throw error;

        navigate(SharedRoute.HOME);

        return data;
      } catch (err: any) {
        setError(err.message);
        return null;
      }
    },
    [currentFactorId, challengeId]
  );

  // Verify the provided MFA code
  const verifyFactor = useCallback(
    async (code: string, challengeId: string) => {
      try {
        if (!currentFactorId || !challengeId)
          throw new Error(
            "No factor or challenge ID available for verification."
          );

        const { data, error } = await supabase.auth.mfa.verify({
          factorId: currentFactorId,
          challengeId,
          code,
        });
        if (error) throw error;

        return data;
      } catch (err: any) {
        setError(err.message);
        return;
      }
    },
    [currentFactorId, challengeId]
  );

  // Initialize hook and fetch AAL on mount
  useEffect(() => {
    getAAL();
    listFactors();
  }, [getAAL]);

  return {
    currentLevel,
    nextLevel,
    shouldImplementMFA,
    shouldEnrollMFA,
    shouldVerifyMFA,
    currentFactorId,
    challengeId,
    aalData,
    error,
    getAAL,
    listFactors,
    enrollTOTPFactor,
    enrollPhoneFactor,
    executeChallenge,
    verifyChallenge,
    verifyFactor,
    unenrollFactor,
    setCurrentFactorId,
    setError,
    getFactorByType,
    factorsList,
    factorsRetrieved,
  };
};

export default useMFA;
