import { useServiceResources } from "backend/resources/services/serviceResource";
import { ServiceFilterTypes } from "state/serviceFilters/serviceFilters";
import ServiceProvidersTable from "components/Tables/ServiceProvidersTable";
import { ServiceFilters } from "components/Services Hub/components/ServiceFilters";

export default function ServiceProvidersTab() {
  const { data } = useServiceResources();

  return (
    <div>
      <ServiceFilters
        filterConfig={{
          [ServiceFilterTypes.ORG]: true,
          [ServiceFilterTypes.CARESPACE]: true,
          [ServiceFilterTypes.PROVIDER]: true,
          [ServiceFilterTypes.SERVICE_TYPE]: true,
          [ServiceFilterTypes.HIDE_INACTIVE]: true,
        }}
      />
      <ServiceProvidersTable data={data ?? []} />
    </div>
  );
}
