import { supabase } from "clients/supabaseClient";

import type { Filter } from "../types";

const all = ["tasks"];
const lists = [...all, "list"];

export const queryKeys = {
  all,
  lists,
  list: (filter: Filter) => [...lists, filter],
};

export const select = supabase.from("plan_entry").select(
  `
    id,
    name,
    description,
    status,
    scheduled_date_time,
    type,
    user!plan_entry_user_id_fkey(
      id,
      first_name,
      last_name,
      profile_image
    )
  `
);
