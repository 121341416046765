import { useState } from "react";
import { useOrgs } from "backend/resources/orgRole";
import { useServiceResources } from "backend/resources/services/serviceResource";
import {
  OrgRoleType,
  OrgRoleTypeToLabel,
} from "backend/resources/userRole/types";
import { useActiveOrganizationId } from "state/organization/organization";
import { ServiceFilterTypes } from "state/serviceFilters/serviceFilters";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { ServiceFilters } from "components/Services Hub/components/ServiceFilters";
import ServiceProvidersTable from "components/Tables/ServiceProvidersTable";
import {
  ListType,
  RoleTableFields,
  UserRoleTable,
} from "components/Tables/UserRoleTable";
import Tabs from "components/Tabs/Tabs";

export enum DirectoryTabs {
  Internal = "Internal",
  External = "External",
}

export default function DirectoryPage() {
  const [currentTab, setCurrentTab] = useState(DirectoryTabs.Internal);
  return (
    <PageContainer>
      <PageMainHeader text={"Directory"} />
      <Tabs
        tabs={[DirectoryTabs.Internal, DirectoryTabs.External]}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {currentTab === DirectoryTabs.Internal && <InternalDirectorySubPage />}
      {currentTab === DirectoryTabs.External && <ExternalDirectorySubPage />}
    </PageContainer>
  );
}

function InternalDirectorySubPage() {
  const { allOrgIdentities } = useOrgs();
  const activeOrgId = useActiveOrganizationId();

  const activeOrgRoles = allOrgIdentities?.filter(
    (organizationRole) => organizationRole.organization_id === activeOrgId
  );

  return (
    <div>
      <UserRoleTable
        hiddenColumns={[RoleTableFields.WorkSpaceName]}
        data={
          activeOrgRoles?.map((activeOrgRole) => ({
            [RoleTableFields.MemberName]:
              `${activeOrgRole.user.first_name} ${activeOrgRole.user.last_name}` ??
              "",
            [RoleTableFields.Role]:
              OrgRoleTypeToLabel[activeOrgRole.role as OrgRoleType] ?? "",
            [RoleTableFields.Id]: activeOrgRole.organization?.id ?? "",
            [RoleTableFields.UserId]: activeOrgRole.user.id ?? "",
            [RoleTableFields.WorkSpaceName]:
              activeOrgRole.organization?.name ?? "",
            [RoleTableFields.roleObject]: activeOrgRole,
          })) ?? []
        }
        listType={ListType.Users}
      />
    </div>
  );
}

function ExternalDirectorySubPage() {
  const { data } = useServiceResources();

  return (
    <div>
      <ServiceFilters
        filterConfig={{
          [ServiceFilterTypes.ORG]: true,
          [ServiceFilterTypes.CARESPACE]: true,
          [ServiceFilterTypes.PROVIDER]: true,
          [ServiceFilterTypes.SERVICE_TYPE]: true,
          [ServiceFilterTypes.HIDE_INACTIVE]: true,
        }}
      />
      <ServiceProvidersTable data={data ?? []} />
    </div>
  );
}
