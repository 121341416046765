import type { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { AssessmentsTab } from "components/CareBinder/components/AssessmentsTab";
import DiagnosisTab from "components/CareBinder/components/DiagnosisTab";
import CarePlan from "components/CarePlan/CarePlan";
import { CarespaceCareTeamTab } from "components/CarespacePage/Tabs/CarespaceCareTeamTab";
import { CarespaceMainTab } from "components/CarespacePage/Tabs/CarespaceMainTab/CarespaceMainTab";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { NanasDay } from "components/NanasDay";
import type { FC } from "react";

/**
 * Props for the CarespaceTabContent component.
 */
interface CarespaceTabContentProps {
  /** The type of tab to display */
  tabType: CarespacePageTabs;
  /** Network data, required for the main tab */
  network?: NetworksWithAdlosAndCaregivers | null | undefined;
}

/**
 * CarespaceTabContent component
 *
 * This component renders the content for different tabs in the Carespace page.
 * It uses a switch-case like structure with conditional rendering to display
 * the appropriate content based on the selected tab type.
 */
export const CarespaceTabContent: FC<CarespaceTabContentProps> = ({
  tabType,
  network,
}) => (
  <div className="flex flex-col gap-2 mt-8">
    {/* Main tab */}
    {tabType === CarespacePageTabs.CARE_PLAN && (
      <CarespaceMainTab network={network} />
    )}

    {/* Care Team tab */}
    {tabType === CarespacePageTabs.CARE_TEAM && <CarespaceCareTeamTab />}

    {/* Care Plan tab */}
    {tabType === CarespacePageTabs.COACHING_PLAN && <CarePlan />}

    {/* Assessments tab */}
    {tabType === CarespacePageTabs.ASSESSMENTS && <AssessmentsTab />}

    {/* Medications tab */}
    {tabType === CarespacePageTabs.MEDICATIONS && <NanasDay />}

    {/* Diagnosis tab */}
    {tabType === CarespacePageTabs.DIAGNOSIS && <DiagnosisTab />}
  </div>
);
