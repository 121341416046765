import create from "zustand";

export type DiscussionFilterState = {
  org: string | undefined;
  carespace: string | undefined;
  type: string | undefined;
  setOrg: (org: string | undefined) => void;
  setCarespace: (carespace: string | undefined) => void;
  setType: (type: string | undefined) => void;
};

export const useDiscussionFilterStore = create<DiscussionFilterState>(
  (set) => ({
    org: undefined,
    carespace: undefined,
    type: "All",
    setOrg: (org: string | undefined) =>
      set(() => {
        // Reset carespace when org changes
        return { org, carespace: undefined };
      }),
    setCarespace: (carespace: string | undefined) => set(() => ({ carespace })),
    setType: (type: string | undefined) => set(() => ({ type })),
  })
);
