import {
  TaskStatus,
  TaskStatusLabel,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import { useServiceRequestsFromServiceResource } from "backend/resources/services/serviceResource";
import { DefaultCheckbox } from "components/Checkbox";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Select } from "components/Select";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import {
  TaskTableFields,
  TaskTableType,
} from "components/Tables/TaskTable/TaskTableTypes";
import { TaskDonutCharts } from "components/TaskNavigatorPage/TaskDonutChart";
import {
  TaskFilterTypes,
  TaskFilters,
} from "components/TaskNavigatorPage/TaskFilters";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useParams } from "react-router-dom";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";

export default function ServiceProviderServicesRequestsTab() {
  const navigate = useAppNavigate();
  const { id: service_resource_id } = useParams<{ id: string }>();

  const { setStatus, status, hideDone, setHideDone } = useTaskFilterStore();
  const { data: serviceRequests, isLoading } =
    useServiceRequestsFromServiceResource(service_resource_id);
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;

  function sortRowsFunction(a: TaskTableType, b: TaskTableType) {
    if (new Date(a.Due).getTime() !== new Date(b.Due).getTime()) {
      return new Date(a.Due) < new Date(b.Due) ? -1 : 1;
    } else {
      const carespaceComparison = a.Carespace.localeCompare(b.Carespace);
      if (carespaceComparison !== 0) return carespaceComparison;
      return a[TaskTableFields.ServiceStatus].localeCompare(
        b[TaskTableFields.ServiceStatus]
      );
    }
  }

  if (isLoading) {
    return <LoadingSpinner className="w-40 m-auto" />;
  }
  return (
    <div className=" flex flex-col gap-4 pb-20">
      <div className="mt-4 flex items-center md:w-[1000px] md:min-w-[1000px]">
        <TaskDonutCharts
          data={serviceRequests ?? []}
          title="Ticket Status"
          filterConfig={{
            [TaskFilterTypes.CARESPACE]: true,
            [TaskFilterTypes.WHO]: true,
            [TaskFilterTypes.DUE_DATE]: true,
          }}
        />
        <TaskFilters
          filterConfig={{
            [TaskFilterTypes.CARESPACE]: true,
            [TaskFilterTypes.WHO]: true,
            [TaskFilterTypes.DUE_DATE]: true,
          }}
        />{" "}
      </div>
      <div className="flex gap-20 items-center">
        <div className="flex gap-5 items-center">
          <p>Status</p>
          <Select
            currentOption={
              status
                ? { value: status, label: status }
                : { value: "All", label: "All" }
            }
            options={[
              { value: "All", label: "All" },
              ...Object.values(TaskStatus).map((status) => ({
                label: TaskStatusLabel[status],
                value: status,
              })),
              { value: "Overdue", label: "Overdue" },
            ]}
            onChange={(status) => setStatus(status)}
          />
          {status === "All" && (
            <div className="flex gap-2 items-center">
              <DefaultCheckbox
                checked={hideDone}
                onChange={(isChecked: boolean) => setHideDone(isChecked)}
              />
              <p className="text-sm">Hide Done</p>
            </div>
          )}
        </div>
      </div>
      <TaskTable
        updateTask={updatePlanEntry}
        data={serviceRequests ?? []}
        filterConfig={{
          [TaskFilterTypes.STATUS]: true,
          [TaskFilterTypes.CARESPACE]: true,
          [TaskFilterTypes.WHO]: true,
          [TaskFilterTypes.DUE_DATE]: true,
          [TaskFilterTypes.HIDE_DONE]: true,
        }}
        sortFunction={sortRowsFunction}
        handleClick={(cell, row) =>
          navigate({
            path: SharedRoute.SERVICE_REQUEST_VIEW,
            params: {
              id: row.original[TaskTableFields.Id],
            },
          })
        }
        hiddenColumns={[TaskTableFields.Category]}
      />
    </div>
  );
}
