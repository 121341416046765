import ErrorIcon from "assets/error.svg?react";
import LoadingGoalsIcon from "assets/loading-goals.gif";
import { getCarePlanPdfURL } from "backend/functions";
import {
  CarePlanStatuses,
  useCurrentCarePlanGoalsQuery,
  useLatestCarePlan,
} from "backend/resources/carePlan/carePlan";
import {
  CarePlanGenerationStatuses,
  useCarePlanGenerationStatus,
} from "backend/resources/carePlanGenerationStatus";
import type { GoalWithStatus } from "backend/resources/goal/goal";
import { useGoalQuery } from "backend/resources/goal/goal";
import { useActiveUserAdlo } from "backend/resources/userAdlo";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import CardLayoutSelector, {
  CardLayoutTypes,
} from "components/CardLayoutSelector/CardLayoutSelector";
import { DefaultCheckbox } from "components/Checkbox";
import GoalCard from "components/GoalPage/Goal/GoalCard";
import GoalRating from "components/GoalPage/Rating";
import { TimeFrame, Timeline } from "components/GoalPage/utils";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Select } from "components/Select";
import { useHasPlanBinderControl } from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useEffect, useState } from "react";
import ActionButtons from "shared/ui/action-buttons";

import CarePlanStatusIcon from "components/CareBinder/components/CarePlanStatusIcon";

export default function CarePlan() {
  const navigate = useAppNavigate();

  /// ////
  // Local State
  const [timeframe, setTimeframe] = useState<string | undefined>("All");
  const [activeLayout, setActiveLayout] = useState(CardLayoutTypes.DASHBOARD);

  /// ////
  // Stores

  const { isMobile } = useWindowSize();

  /// ////
  // Hooks

  const userAdlo = useActiveUserAdlo();
  const { data: hasCarePlanControl, isLoading: isLoadingHasCarePlanControl } =
    useHasPlanBinderControl();
  const { data: goalStatus, isLoading: isLoadingGoalStatus } =
    useCarePlanGenerationStatus();
  const { data: goals, isLoading: isLoadingGoals } =
    useCurrentCarePlanGoalsQuery();
  const { data: latestCarePlan, isLoading: isLoadingLatestCarePlan } =
    useLatestCarePlan();
  const { suggestedGoals } = useGoalQuery();
  /// ////
  // Effects

  useEffect(() => {
    if (!activeLayout && !isLoadingHasCarePlanControl) {
      setActiveLayout(CardLayoutTypes.DASHBOARD);
    }
  }, [hasCarePlanControl, activeLayout, isLoadingHasCarePlanControl]);

  /// ////
  // Constants

  const numberOfGoalsInCarePlan = goals?.length ?? 0;
  const numberOfSuggestedGoals = suggestedGoals?.length ?? 0;

  const goalsByTimeline: Record<Timeline, GoalWithStatus[]> = {
    short: goals?.filter((goal) => goal.timeline === Timeline.SHORT_TERM) ?? [],
    mid: goals?.filter((goal) => goal.timeline === Timeline.MID_TERM) ?? [],
    long: goals?.filter((goal) => goal.timeline === Timeline.LONG_TERM) ?? [],
  };

  const options = [
    { label: "All", value: "All" },
    ...TimeFrame.getDropDownOptions(),
  ];

  /// ////
  // React Element

  if (goalStatus?.status === CarePlanGenerationStatuses.ERROR) {
    <div className="flex  flex-col items-center pb-2 md:-mt-4 md:justify-end ">
      <ErrorIcon className="w-20 h-20 justify-center flex  bg-[#bd3e30] rounded-full mb-10" />
      <p>
        Something went wrong. Please try again or contact
        support@craniometrix.com.{" "}
      </p>
    </div>;
  }

  if (
    isLoadingGoalStatus ||
    (goalStatus?.status !== CarePlanGenerationStatuses.PENDING &&
      (isLoadingGoals || isLoadingLatestCarePlan))
  ) {
    return (
      <div className="flex items-center justify-center h-[50vh]">
        <LoadingSpinner className="w-10" />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex justify-end w-full">
        <CarePlanStatusIcon goalStatus={goalStatus} />
      </div>
      {/* Action Buttons */}
      {hasCarePlanControl ? (
        <div className="flex gap-3 items-center whitespace-nowrap mb-4">
          <ActionButtons>
            <>
              <ButtonWithIcon
                onClick={() =>
                  navigate({ path: CarePilotRoute.CARE_PLAN_EDIT })
                }
                size="small"
                text={
                  latestCarePlan?.status === CarePlanStatuses.DRAFT
                    ? "Edit Draft Plan"
                    : Object.values(goalsByTimeline).some(
                        (goalsInTimeline) => goalsInTimeline.length > 0
                      )
                    ? "Edit Care Plan"
                    : "Create Care Plan"
                }
                disabled={
                  goalStatus?.status === CarePlanGenerationStatuses.PENDING ||
                  (numberOfSuggestedGoals == 0 &&
                    !Object.values(goalsByTimeline).some(
                      (goalsInTimeline) => goalsInTimeline.length > 0
                    ))
                }
                icon={
                  Object.values(goalsByTimeline).some(
                    (goalsInTimeline) => goalsInTimeline.length > 0
                  ) || latestCarePlan?.status === CarePlanStatuses.DRAFT
                    ? IconOption.EDIT
                    : IconOption.PLUS
                }
              />
              <ButtonWithIcon
                onClick={async () => {
                  if (latestCarePlan?.id) {
                    await getCarePlanPdfURL(latestCarePlan.id, userAdlo);
                  }
                }}
                disabled={numberOfGoalsInCarePlan === 0}
                icon={IconOption.DOWNLOAD}
                text="Download PDF"
              />
            </>
          </ActionButtons>

          {/* Card layout Selector */}
          <div className="flex flex-grow justify-end gap-3 items-center">
            <CardLayoutSelector
              showListMode={true}
              activeLayout={activeLayout ?? CardLayoutTypes.DEFAULT}
              setActiveLayout={setActiveLayout}
            />
          </div>
        </div>
      ) : null}

      <div className="flex justify-between items-center h-9">
        {/* Goal Timeframe selector */}
        {activeLayout === "default" || activeLayout === "thumbnail" ? (
          <div className="flex gap-2 items-center">
            <label>Goals</label>
            <Select
              currentOption={options.find(
                (option) => option.value === timeframe
              )}
              options={options}
              onChange={(item) => {
                setTimeframe(item);
              }}
            />
          </div>
        ) : null}
      </div>

      {/* Empty  Message */}
      {numberOfGoalsInCarePlan == 0 ? (
        <div className="py-4 flex flex-col gap-3 justify-center items-center text-center">
          <EmptyCarePlanMessage />
        </div>
      ) : null}

      {/* Goals */}
      <div className="pb-40">
        {activeLayout === "default" || activeLayout === "thumbnail" ? (
          <div className="flex flex-wrap gap-2 mt-4">
            {goals
              ?.filter(
                (goal) => goal.timeline === timeframe || timeframe === "All"
              )
              .map((goal) => (
                <GoalCard
                  goal={goal}
                  size={activeLayout}
                />
              ))}
          </div>
        ) : null}
        {activeLayout === "skinny" && numberOfGoalsInCarePlan > 0 ? (
          <div className="flex flex-col gap-4 mt-4">
            <GoalsComponent goalsByTimeline={goalsByTimeline} />
          </div>
        ) : null}
      </div>
    </div>
  );

  function GoalsComponent({
    goalsByTimeline,
  }: {
    goalsByTimeline: Record<Timeline, GoalWithStatus[]>;
  }) {
    // Check if there are any goals in any of the keys
    return (
      <div className="pb-5">
        <div className="grid grid-cols-[1fr,.5fr,.25fr,.1fr] gap-2 mt-4 text-center">
          <p className="text-start">Time Frame</p>
          <p>Date Added</p>
          <p>Progress</p>
          <p>Results</p>

          {Object.keys(goalsByTimeline).map((timeline) => (
            <>
              <h2 className="font-bold text-sm text-start pt-5">
                {TimeFrame.getLabelFromValue(timeline)}
              </h2>
              <p /> <p /> <p />
              {goalsByTimeline[timeline as Timeline].length === 0 ? (
                <>
                  <p className="text-start text-sm">None</p> <p /> <p /> <p />
                </>
              ) : (
                goalsByTimeline[timeline as Timeline].map(
                  (goal: GoalWithStatus) => (
                    <>
                      <button
                        className="line-clamp-2 text-sm text-left break-words"
                        onClick={() => {
                          navigate({
                            path: CarePilotRoute.GOAL_ID,
                            params: { id: goal.id },
                          });
                        }}>
                        <p className="line-clamp-2 text-sm text-left break-words">
                          {goal.title}
                        </p>
                      </button>
                      <p className="text-sm">
                        {new Date(goal.created_at).toLocaleDateString()}
                      </p>
                      {goal.progress === 100 ? (
                        <div className="flex justify-center">
                          <div className="w-min h-min rounded-full overflow-clip">
                            <DefaultCheckbox
                              checked={true}
                              onChange={() => {}}
                            />
                          </div>
                        </div>
                      ) : (
                        <p className="text-sm line-clamp-1">
                          {goal.progress === 0
                            ? ""
                            : `${Math.round(goal.progress)}%`}
                        </p>
                      )}
                      {goal.rating !== null ? (
                        <div className="flex justify-center">
                          <GoalRating
                            rating={goal.rating}
                            size="large"
                          />
                        </div>
                      ) : (
                        <p />
                      )}
                    </>
                  )
                )
              )}
            </>
          ))}
        </div>
      </div>
    );
  }

  function EmptyCarePlanMessage() {
    if (!hasCarePlanControl) {
      // say doctor is reviewing
      return (
        <>
          <p>
            Your doctor or provider is currently working on{" "}
            {userAdlo?.first_name}'s care plan.
          </p>
          <p>You’ll be notified when it is ready to view.</p>
        </>
      );
    } else if (
      hasCarePlanControl &&
      goalStatus?.status === CarePlanGenerationStatuses.PENDING
    ) {
      return (
        <div className="flex flex-col gap-4 items-center">
          <img
            className="w-20 h-20"
            src={LoadingGoalsIcon}
            alt=""
          />
          <p className="text-sm text-center">
            {userAdlo?.first_name}'s suggested care plan is currently being
            created and will be ready shortly.
          </p>
        </div>
      );
    } else if (latestCarePlan?.status === CarePlanStatuses.DRAFT) {
      return (
        <>
          <p>The care plan for {userAdlo?.first_name} is in draft mode.</p>
          <p>
            You can publish it by clicking on
            {isMobile
              ? ' the "..." button and then "Edit Draft Plan"'
              : ' "Edit Draft Plan"'}
            and clicking "Publish".
          </p>
        </>
      );
    } else if (hasCarePlanControl && numberOfSuggestedGoals > 0) {
      // say they need to create a care plan
      return (
        <>
          <p>There is no current care plan for {userAdlo?.first_name}.</p>
          <p>
            Please create one by adding new goals or using ones from the
            Suggested Care Plan by clicking on
          </p>
        </>
      );
    } else if (hasCarePlanControl && numberOfSuggestedGoals === 0) {
      // say they need to take an assessment
      return (
        <>
          <p>There is no care plan yet. </p>
          <p>Please take an assessment to generate a suggested care plans</p>
        </>
      );
    } else {
      return null;
    }
  }
}
