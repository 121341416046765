import { DatePicker } from "@mui/x-date-pickers";
import CollapseIcon from "assets/collapse-all-icon.svg?react";
import type { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { useOrgs } from "backend/resources/orgRole";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { Sex } from "components/CarespacePage/PAFSubmission/pafEnums";
import { PAFStatusLabelMap } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import {
  CarespaceTableFields,
  DYAD,
} from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import {
  getCarespaceNameFromCarespace,
  getUserRoleMapFromCarespace,
} from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/util";
import { Select } from "components/Select";
import { TaskFilter } from "components/TaskNavigatorPage/TaskFilters";
import type { FC } from "react";
import { useMemo, useState } from "react";
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter";

const getCurrentOption = (
  optionValue: string | undefined,
  options: Array<{ value: string; label: string }>
): { value: string | undefined; label: string } =>
  options.find((o) => o.value === optionValue) ?? options[0];

interface CarespaceTableFiltersProps {
  carespaces: NetworksWithAdlosAndCaregivers[];
  isLoading: boolean;
}

const DEFAULT_OPTION = { value: "All", label: "All" };
const TBD_STATUS = "TBD";

export const CarespaceTableFilters: FC<CarespaceTableFiltersProps> = ({
  carespaces,
}) => {
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const {
    org,
    dob,
    sex,
    dyad,
    doctor,
    pcg,
    cn,
    pcp,
    status,
    carespace,
    setOrg,
    setDob,
    setSex,
    setDyad,
    setDoctor,
    setPcg,
    setCn,
    setPcp,
    setStatus,
    setCarespace,
  } = useCarespaceFilterStore();
  const { ownOrgIdentities } = useOrgs();

  const organizations = useMemo(() => {
    // Create a map to deduplicate by name
    const orgMap = new Map<string, { value: string; label: string }>();

    ownOrgIdentities
      ?.filter((org) => org.organization?.name?.trim())
      .forEach((org) => {
        const name = org.organization?.name || "";
        // Only add if we haven't seen this name before
        if (!orgMap.has(name)) {
          orgMap.set(name, {
            value: org.organization_id,
            label: name,
          });
        }
      });

    // Convert map to array and sort
    return (
      [...orgMap.values()].sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [ownOrgIdentities]);

  const getDefaultOptionMap = () => ({
    [DEFAULT_OPTION.label]: DEFAULT_OPTION,
  });

  // Filter carespaces based on selected organization
  const filteredCarespaces = useMemo(() => {
    if (!org || org === "All") return carespaces;
    return carespaces.filter((cs) => cs.organization_id === org);
  }, [carespaces, org]);

  // Get carespace options from filtered carespaces
  const carespaceOptions = useMemo(() => {
    const options = [DEFAULT_OPTION];
    const uniqueCarespaces = new Set();

    filteredCarespaces.forEach((cs) => {
      const name = getCarespaceNameFromCarespace(cs);
      if (!uniqueCarespaces.has(name) && name) {
        uniqueCarespaces.add(name);
        options.push({ value: name, label: name || "" });
      }
    });

    return options;
  }, [filteredCarespaces]);

  // build filter options
  const buildFilterOptions = () => {
    const orgOptions = [{ value: "All", label: "All" }, ...organizations];

    const caregiverOptions: Record<string, { value: string; label: string }> =
      getDefaultOptionMap();
    const doctorOptions: Record<string, { value: string; label: string }> =
      getDefaultOptionMap();
    const careNavigatorOptions: Record<
      string,
      { value: string; label: string }
    > = {
      ...getDefaultOptionMap(),
      [TBD_STATUS]: { value: TBD_STATUS, label: TBD_STATUS },
    };
    const pcpOptions: Record<string, { value: string; label: string }> =
      getDefaultOptionMap();

    // iterate through filtered carespaces and add keys to the option maps
    filteredCarespaces.forEach((cs) => {
      const pcpName = cs.primary_care_physician?.name;
      const userRolesMap = getUserRoleMapFromCarespace(cs);
      const caregiverName = userRolesMap[NetworkRoleType.PRIMARY_CAREGIVER];
      const doctorName = userRolesMap[NetworkRoleType.DOCTOR];
      const careNavigatorName = userRolesMap[NetworkRoleType.CARE_NAVIGATOR];

      if (pcpName) pcpOptions[pcpName] = { value: pcpName, label: pcpName };
      if (caregiverName)
        caregiverOptions[caregiverName] = {
          value: caregiverName,
          label: caregiverName,
        };
      if (doctorName)
        doctorOptions[doctorName] = { value: doctorName, label: doctorName };
      if (careNavigatorName)
        careNavigatorOptions[careNavigatorName] = {
          value: careNavigatorName,
          label: careNavigatorName,
        };
    });

    return {
      orgOptions,
      caregiverOptions: Object.values(caregiverOptions),
      doctorOptions: Object.values(doctorOptions),
      careNavigatorOptions: Object.values(careNavigatorOptions),
      pcpOptions: Object.values(pcpOptions),
      sexOptions: [
        DEFAULT_OPTION,
        ...Object.values(Sex).map((sex) => ({ value: sex, label: sex })),
      ],
      dyadOptions: [
        DEFAULT_OPTION,
        ...Object.values(DYAD).map((dyad) => ({ value: dyad, label: dyad })),
      ],
      statusOptions: [
        DEFAULT_OPTION,
        ...Object.values(PAFStatusLabelMap).map((status) => ({
          value: status,
          label: status,
        })),
      ],
    };
  };

  const {
    orgOptions,
    caregiverOptions,
    doctorOptions,
    careNavigatorOptions,
    pcpOptions,
    sexOptions,
    dyadOptions,
    statusOptions,
  } = buildFilterOptions();

  return (
    <div className={`flex flex-col gap-y-4 w-full`}>
      <div className={`grid grid-cols-3 w-full gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.ORG}>
          <Select
            currentOption={getCurrentOption(org, orgOptions)}
            options={orgOptions}
            onChange={(value) => {
              setOrg(value);
              setCarespace(undefined); // Reset carespace when org changes
            }}
            classNames="w-full"
            isSearchable
            placeHolder="Search organizations..."
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.CARESPACE}>
          <Select
            currentOption={getCurrentOption(carespace, carespaceOptions)}
            options={carespaceOptions}
            onChange={(carespace) => setCarespace(carespace)}
            classNames="w-full"
            isSearchable
            placeHolder="Search carespaces..."
          />
        </TaskFilter>
        <div className="flex items-end">
          <TaskFilter
            label={CarespaceTableFields.DOB}
            className="flex-1 px-0">
            <div className="-ml-1">
              <DatePicker
                value={dob}
                onChange={(dob) => {
                  const formattedDob = dob
                    ? new Date(dob).toLocaleDateString("en-US")
                    : undefined;
                  setDob(formattedDob);
                }}
              />
            </div>
          </TaskFilter>
          <button
            onClick={() => setShowMoreFilters(!showMoreFilters)}
            className="flex items-center gap-x-2 text-orange-500 hover:text-orange-600 mb-4 mr-32">
            <CollapseIcon className="w-8 h-i" />
            <span>{showMoreFilters ? "Less filters" : "More filters"}</span>
          </button>
        </div>
      </div>
      {showMoreFilters && (
        <>
          <div className={`grid grid-cols-3 w-full gap-x-5 gap-y-4`}>
            <TaskFilter label={CarespaceTableFields.PCG}>
              <Select
                currentOption={getCurrentOption(pcg, caregiverOptions)}
                options={caregiverOptions}
                onChange={(pcg) => setPcg(pcg)}
                classNames="w-full"
                isSearchable
                placeHolder="Search caregivers..."
              />
            </TaskFilter>
            <TaskFilter label={CarespaceTableFields.STATUS}>
              <Select
                currentOption={getCurrentOption(status, statusOptions)}
                options={statusOptions}
                onChange={(status) => setStatus(status)}
                classNames="w-full"
                isSearchable
                placeHolder="Search statuses..."
              />
            </TaskFilter>
            <TaskFilter label={CarespaceTableFields.SEX}>
              <Select
                currentOption={getCurrentOption(sex, sexOptions)}
                options={sexOptions}
                onChange={(sex) => setSex(sex)}
                classNames="w-full"
                isSearchable
                placeHolder="Search sexes..."
              />
            </TaskFilter>
          </div>
          <div className={`grid grid-cols-3 w-full gap-x-5 gap-y-4`}>
            <TaskFilter label={CarespaceTableFields.DYAD}>
              <Select
                currentOption={getCurrentOption(dyad, dyadOptions)}
                options={dyadOptions}
                onChange={(dyad) => setDyad(dyad)}
                classNames="w-full"
                isSearchable
                placeHolder="Search dyads..."
              />
            </TaskFilter>
            <TaskFilter label={CarespaceTableFields.DOCTOR}>
              <Select
                currentOption={getCurrentOption(doctor, doctorOptions)}
                options={doctorOptions}
                onChange={(doctor) => setDoctor(doctor)}
                classNames="w-full"
                isSearchable
                placeHolder="Search doctors..."
              />
            </TaskFilter>
            <TaskFilter label={CarespaceTableFields.PCP}>
              <Select
                currentOption={getCurrentOption(pcp, pcpOptions)}
                options={pcpOptions}
                onChange={(pcp) => setPcp(pcp)}
                classNames="w-full"
                isSearchable
                placeHolder="Search PCPs..."
              />
            </TaskFilter>
          </div>
          <div className={`grid grid-cols-3 w-full gap-x-5 gap-y-4`}>
            <TaskFilter label={CarespaceTableFields.CN}>
              <Select
                currentOption={getCurrentOption(cn, careNavigatorOptions)}
                options={careNavigatorOptions}
                onChange={(cn) => setCn(cn)}
                classNames="w-full"
                isSearchable
                placeHolder="Search care navigators..."
              />
            </TaskFilter>
          </div>
        </>
      )}
    </div>
  );
};
