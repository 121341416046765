import { HighChartDataPoint } from "components/HighChart";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef } from "react";

// Props interface for the HighPieChart component
export interface HighPieChartProps {
  data: HighChartDataPoint[]; // Array of data points for the chart
  title?: string; // Title of the chart
  titleMargin?: number; // Margin of the title
  isLoading?: boolean; // Whether the chart is loading
  chartMargin?: number; // Margin of the chart
  convertToDonut?: boolean; // Option to convert pie chart to donut chart
  showDataLabels?: boolean; // Option to show data labels on the chart
  showTooltip?: boolean; // Option to enable tooltips
  showLegend?: boolean; // Option to show the legend
  size?: string; // Size of the chart as a percentage (e.g., '100%')
  height?: string; // Height of the chart as a percentage (e.g., '100%')
}

/**
 * HighPieChart component for rendering customizable pie or donut charts using Highcharts.
 *
 * Usage:
 * <HighPieChart
 *   data={[{ name: 'Category 1', y: 50 }, { name: 'Category 2', y: 30 }, { name: 'Category 3', y: 20 }]}
 *   title="Sample Chart"
 *   convertToDonut={true}
 *   showDataLabels={true}
 *   showTooltip={true}
 *   showLegend={true}
 *   size="80%"
 *   height="300px"
 * />
 *
 * @param props - The properties for configuring the chart
 * @returns A React component rendering a Highcharts pie or donut chart
 */
export const HighPieChart: React.FC<HighPieChartProps> = ({
  data,
  title,
  titleMargin,
  chartMargin,
  isLoading = false,
  convertToDonut = false,
  showDataLabels = false,
  showTooltip = false,
  showLegend = false,
  size = 180,
  height = "50%",
}) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      if (isLoading) {
        chartRef.current.chart.showLoading("Loading data...");
      } else {
        chartRef.current.chart.hideLoading();
      }
    }
  }, [isLoading]);

  // Configuration options for the Highcharts chart
  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      margin: chartMargin ? chartMargin : undefined,
      height: height,
      alignTicks: false,
    },
    title: {
      text: title ? title : undefined,
      margin: titleMargin ? titleMargin : undefined,
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ["45%", "50%"], // chart alignment in container
        innerSize: convertToDonut ? "50%" : undefined,
        showInLegend: showLegend,
        size: size, // Slightly reduce the pie size to fit better
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      enabled: showLegend,
      labelFormat: "{name}: {y}",
      x: 0,
      itemStyle: {
        fontSize: "12px",
      },
    },
    loading: {
      hideDuration: 100,
      showDuration: 100,
      labelStyle: {
        color: "#666666",
        fontSize: "16px",
        fontWeight: "bold",
      },
      style: {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    },
    tooltip: {
      enabled: showTooltip,
      pointFormat: "{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)", // Tooltip format
    },
    series: [
      {
        name: "Status",
        type: "pie",
        data:
          data.length > 0
            ? data
            : [{ name: "No data", y: 100, color: "#d1d5db" }], // bg-gray-300 in tailwind
        size: size,
        dataLabels: {
          enabled: showDataLabels,
          format: "{point.name}: {point.y} ({point.percentage:.1f}%)", // Data label format
        },
        keys: ["name", "y"], // Add this line to specify keys
      } as Highcharts.SeriesPieOptions,
    ],
  };

  // Render the Highcharts component with the configured options
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartRef}
    />
  );
};
