import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "shared/utils/cn";

const badgeVariants = cva(
  "inline-flex items-center whitespace-nowrap rounded-full text-white px-2.5 py-0.5 text-xs transition-colors dark:border-neutral-800 dark:focus:ring-neutral-300",
  {
    variants: {
      variant: {
        default:
          "dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/80",
        outline: "border dark:text-neutral-50",
      },
      color: {
        gray: "",
        blue: "",
        salmon: "",
        green: "",
        orange: "",
        purple: "",
        brightGreen: "",
      },
    },
    compoundVariants: [
      {
        variant: "default",
        color: "gray",
        class: "bg-[#CFCFCF]",
      },
      {
        variant: "outline",
        color: "gray",
        class: "text-[#CFCFCF] border-[#CFCFCF]",
      },
      {
        variant: "default",
        color: "blue",
        class: "bg-[#7DC3F6]",
      },
      {
        variant: "outline",
        color: "blue",
        class: "text-[#7DC3F6] border-[#7DC3F6]",
      },
      {
        variant: "default",
        color: "salmon",
        class: "bg-[#F79393]",
      },
      {
        variant: "outline",
        color: "salmon",
        class: "text-[#F79393] border-[#F79393]",
      },
      {
        variant: "default",
        color: "green",
        class: "bg-[#78D08F]",
      },
      {
        variant: "outline",
        color: "green",
        class: "text-[#78D08F] border-[#78D08F]",
      },
      {
        variant: "default",
        color: "orange",
        class: "bg-[#F6B26B]",
      },
      {
        variant: "outline",
        color: "orange",
        class: "text-[#F6B26B] border-[#F6B26B]",
      },
      {
        variant: "default",
        color: "purple",
        class: "bg-[#8185F0]",
      },
      {
        variant: "outline",
        color: "purple",
        class: "text-[#8185F0] border-[#8185F0]",
      },
      {
        variant: "default",
        color: "brightGreen",
        class: "bg-[#25C322]",
      },
      {
        variant: "outline",
        color: "brightGreen",
        class: "text-[#25C322] border-[#25C322]",
      },
    ],
    defaultVariants: {
      variant: "default",
      color: "gray",
    },
  }
);

export interface BadgeProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "color">,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, color, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, color }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
