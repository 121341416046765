import { useState } from "react";

export interface ControlledProps<Value> {
  defaultValue?: Value;
  value?: Value;
  onChange?: (newValue: Value) => void;
}

export default function useControlledProps<Value>(
  props: ControlledProps<Value>
) {
  const [defaultValue, setDefaultValue] = useState<Value | undefined>(
    props.defaultValue
  );

  if (props.value && props.onChange) {
    return {
      value: props.value ?? props.defaultValue,
      onChange: props.onChange
    }
  }

  return {
    value: defaultValue,
    onChange: (newValue: Value) => {
      if (props.onChange) {
        props.onChange(newValue);
      }

      setDefaultValue(newValue);
    },
  };
}
