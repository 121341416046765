import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import * as React from "react";

import { Button } from "shared/ui/button";
import { Calendar } from "shared/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "shared/ui/popover";

import { cn } from "shared/utils/cn";

import useControlledProps, {
  type ControlledProps,
} from "shared/hooks/use-controlled-props";

export interface DatePickerProps extends ControlledProps<Date> {
  className?: string;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
}

const DatePicker = React.forwardRef<
  React.ElementRef<typeof Button>,
  DatePickerProps
>(({ className, id, disabled, placeholder = "Select date", ...props }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { value, onChange } = useControlledProps(props);

  return (
    <Popover
      open={open}
      onOpenChange={(newOpen) => !disabled && setOpen(newOpen)}>
      <PopoverTrigger asChild>
        <Button
          ref={ref}
          id={id}
          variant="combobox"
          role="combobox"
          disabled={disabled}
          aria-expanded={open}
          className={cn(className, "justify-start")}>
          <CalendarIcon className="mr-2 h-4 w-4 text-brand-orange" />
          {value ? format(value, "PPP") : placeholder}
        </Button>
      </PopoverTrigger>

      <PopoverContent
        className="p-2"
        sideOffset={6}
        align="start">
        <Calendar
          mode="single"
          selected={value}
          onSelect={(date) => date && onChange(date)}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
});
DatePicker.displayName = "DatePicker";

export { DatePicker };
