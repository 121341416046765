import { TaskStatusColor, TaskStatusLabel } from "backend/resources/planEntry";

export enum TaskStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Done = "done",
  NotApplicable = "not_applicable",
}

export const RECURRING_INTERVAL_OPTIONS = [
  { value: "None", label: "N/A" },
  { value: "1 day", label: "Daily" },
  { value: "7 days", label: "Weekly" },
  { value: "1 mon", label: "Monthly" },
  { value: "3 mons", label: "Quarterly" },
  { value: "14 days", label: "Bi-Weekly" },
  { value: "1 year", label: "Yearly" },
];

export const STATUS_OPTIONS = Object.entries(TaskStatusLabel).map(
  ([key, label]) => ({
    value: key,
    label,
    background: TaskStatusColor[key as TaskStatus],
  })
);

export const GUIDE_IDENTIFIER_FOR_RECURRING_CALLS = "4.2";
