/**
 * @fileoverview Sign In page.
 */

import { track } from "analytics/track";
import GoogleLogo from "assets/google-logo.png";
import { acceptOrgInvitation } from "backend/functions";
import { supabase } from "clients/supabaseClient";
import { Button } from "components/Button";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  InactivityAlert,
  LogoutAlert,
  WrongLinkAlert,
} from "components/LogIn/LogoutStatusAlert";
import { TextInput } from "components/TextInput";
import useMFA from "hooks/useMFA/useMFA";
import { SharedRoute, useAppNavigate } from "lib/routing";
import queryString from "query-string";
import type { ChangeEvent, FormEvent, MouseEvent } from "react";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLogoutReasonStore } from "state/logout/logout";
import { useIsAuthenticated, useUserStore } from "state/user";

const REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL;

/**
 * Component for the Log In page.
 */
export function LogIn() {
  const { search } = useLocation();
  const navigate = useAppNavigate();
  const queryParams = queryString.parse(search);

  const invitationId = queryParams.invitation_id as string;
  const authUser = useUserStore((state) => state.user);

  const isAuthenticated = useIsAuthenticated();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const signIn = useUserStore((state) => state.signIn);
  const [error, setError] = useState<string | null>();
  const [showPasswordRecoverySuccess, setShowPasswordRecoverySuccess] =
    useState(false);
  const [showResendEmail, setShowResendEmail] = useState(false);
  const [showResendEmailSuccess, setShowResendEmailSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // logout logic
  const logoutStatus = useLogoutReasonStore((state) => state.logoutStatus);
  const setLogoutStatus = useLogoutReasonStore(
    (state) => state.setLogoutStatus
  );
  const logoutReason = useLogoutReasonStore((state) => state.logoutReason);
  const setLogoutReason = useLogoutReasonStore(
    (state) => state.setLogoutReason
  );

  const { shouldImplementMFA, aalData, getAAL } = useMFA();

  useEffect(() => {
    if (search) {
      if (queryParams?.email && typeof queryParams.email === "string") {
        setEmail(queryParams.email);
      }
    }
  }, [search]);

  useEffect(() => {
    track({ type: "Page View", properties: { name: "log_in" } });
  }, []);

  async function sendResetPasswordEmail(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (!email) {
      setError("Please enter your email above.");
    }
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${REDIRECT_URL}/reset-password`,
    });

    if (data) {
      setShowPasswordRecoverySuccess(true);
    }

    if (error) {
      setError(error.message);
    }
  }

  async function logIn(
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) {
    setError("");
    e.preventDefault();

    if (!email) {
      setError("You must enter your email");
      return;
    }

    if (!password) {
      setError("You must enter your password");
      return;
    }

    const { error } = await signIn({
      email,
      password,
    });
    if (error) {
      if (error.message === "Email not confirmed") {
        setShowResendEmail(true);
      }
      setError(error.message);
    } else {
      setEmail("");
      setPassword("");
    }
  }

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setError(null);
    setShowPasswordRecoverySuccess(false);
    setEmail(e.currentTarget.value);
  }

  function onChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setError(null);
    setShowPasswordRecoverySuccess(false);
    setPassword(e.currentTarget.value);
  }

  async function resendEmailConfirmation() {
    if (email && password) {
      const { error } = await supabase.auth.signInWithOtp({ email });
      if (!error) {
        setShowResendEmailSuccess(true);
        setError("");
        setShowResendEmail(false);
      }
    }
  }

  async function signInWithGoogle() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });
    if (error) {
      setError("Something went wrong.");
    }
  }

  const MFABehavior = useCallback(
    async (invitationId: string) => {
      if (shouldImplementMFA) {
        navigate({ path: SharedRoute.MFA });
        return;
      }

      navigate({
        path: SharedRoute.HOME,
        queryParams: {
          "accepted-invitation": invitationId ? String(true) : null,
          "invitation-id": invitationId,
        },
      });
    },
    [shouldImplementMFA, aalData]
  );

  useEffect(() => {
    async function acceptInvitation() {
      if (isAuthenticated) {
        await getAAL();
        // reset logout status
        setLogoutStatus("");
        setLogoutReason(undefined);
        // navigate to home
        requestAnimationFrame(async () => {
          if (invitationId && authUser) {
            setIsLoading(true);
            await acceptOrgInvitation(authUser.id, invitationId);
            setIsLoading(false);
          }
        });
        MFABehavior(invitationId);
      }
    }
    acceptInvitation();
  }, [isAuthenticated, invitationId, authUser, aalData, shouldImplementMFA]);

  if (isLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <LoadingSpinner className="h-10 w-10" />
      </div>
    );
  }

  return (
    <div className="md:bg-brand-blue w-full h-full flex flex-col items-center md:justify-center">
      {!!logoutStatus && logoutReason == "USER_INITIATED" ? (
        <div className="w-full px-6 md:px-0 pb-2 md:w-[500px]">
          <LogoutAlert />
        </div>
      ) : !!logoutStatus && logoutReason === "WRONG_LINK" ? (
        <div className="w-full px-6 md:px-0 pb-2 md:w-[500px]">
          <WrongLinkAlert />
        </div>
      ) : !!logoutStatus ? (
        <div className="w-full px-6 md:px-0 pb-2 md:w-[500px]">
          <InactivityAlert />
        </div>
      ) : null}
      <div className="w-full md:max-w-[500px] bg-white px-6 sm:px-12 sm:pt-20 sm:pb-12 rounded-md md:shadow-sm md:border border-faint-gray flex flex-col items-center gap-4">
        <h1 className="font-semibold text-xl md:text-3xl">
          Welcome to Craniometrix
        </h1>
        <form
          onSubmit={logIn}
          className="w-full flex flex-col items-center">
          <div className="w-full flex flex-col gap-4">
            <div className="flex flex-col w-full">
              <label
                className="text-black text-xs ml-1"
                htmlFor="email">
                Email
              </label>
              <TextInput
                data-hj-allow
                type="email"
                onChange={onChangeEmail}
                placeholder="Enter your email address"
                value={email}
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                className="text-black text-xs ml-1"
                htmlFor="password">
                Password
              </label>
              <TextInput
                data-hj-allow
                type="password"
                onChange={onChangePassword}
                placeholder="Enter your password"
                value={password}
              />
              <button
                type="button"
                className="self-end text-xs mt-2 text-blue-500 hover:text-blue-800"
                onClick={sendResetPasswordEmail}>
                Forgot password?
              </button>
            </div>
          </div>
          {showPasswordRecoverySuccess && (
            <p className="text-sm mt-2">
              We sent you an email to help you recover your password.
            </p>
          )}
          {showResendEmailSuccess && <p>Success! Please check your email.</p>}
          {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}
          {showResendEmail && (
            <button
              type="button"
              className="text-blue-500"
              onClick={resendEmailConfirmation}>
              Resend email?
            </button>
          )}
          <div className="flex flex-col w-full mt-6">
            <Button
              className="w-full"
              onClick={logIn}>
              Log In
            </Button>
          </div>
        </form>
        <div className="relative flex justify-center h-2 border-t border-faint-gray w-full mt-4">
          <div className="absolute -top-3 text-gray-400 border border-faint-gray z-10 px-1 rounded-lg bg-white">
            OR
          </div>
        </div>
        <button
          className="mt-2 flex items-center gap-3 border rounded-sm shadow-sm p-2.5"
          onClick={() => {
            signInWithGoogle();
          }}>
          <img
            className="w-4 h-4"
            src={GoogleLogo}
            width="50%"
            alt="google-icon"
          />
          <p className="font-semibold text-gray-500">Sign in with Google</p>
        </button>
        {/* <div className="mt-6 md:self-end flex">
          <p className="mr-1 text-center md:text-left">
            Don't have an account yet?
          </p>
          <Link
            to="/sign-up"
            className="text-blue-500 hover:text-blue-800">
            Sign up
          </Link>
        </div> */}
      </div>
    </div>
  );
}
