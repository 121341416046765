import { useQuery } from "@tanstack/react-query";

import type { Filter, Task } from "../types";

import { queryKeys, select } from ".";

export function useFetchMany(filter?: Filter, enabled = true) {
  return useQuery({
    queryKey: filter ? queryKeys.list(filter) : queryKeys.lists,
    queryFn: async () => {
      let query = select;

      if (filter?.equals) {
        query = query.match(filter.equals);
      }

      const { data, error } = await query;

      if (error) {
        throw error;
      }

      return data as Task[];
    },
    enabled,
  });
}
