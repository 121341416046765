import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useGptStore } from "state/gpt";
import { useUserStore } from "state/user";
import AlfredIcon from "components/Alfred/Alfred";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function SideBarNanasDayPage() {
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col max-h-full h-full w-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"nanasDayPage"}
        pageType="nanasDayPage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  const navigate = useAppNavigate();
  const authUser = useUserStore((state) => state.user);

  const { isMobile } = useWindowSize();
  const setIsOpen = useGptStore((state) => state.setIsOpen);

  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6 bg-gray-50  text-sm px-4 py-4 rounded-md">
        {/* intro PP */}
        <p>
          This is where you plan out your loved one’s daily schedule for things
          like medication management. Over time we’ll be adding the ability to
          add more things to their day so you’ll have one place to look and take
          action.
        </p>
        {/* action buttons */}
        <div className="flex flex-col gap-3">
          <ButtonWithIcon
            onClick={async () => {
              if (isMobile) setIsOpen(false);
              navigate({
                path: CarePilotRoute.DAY_INTAKE,
              });
            }}
            disabled={false}
            text={"Add prescription"}
            size={"small"}
            icon={IconOption.ARROW}
          />
        </div>
      </div>
    </div>
  );
}
