import { createColumnHelper } from "@tanstack/react-table";
import type { FC } from "react";
import { useEffect, useState } from "react";

import { downloadPafFile } from "backend/functions/generatePafFile/generatePafFile";
import type { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { useOrgs } from "backend/resources/orgRole";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { buildPAFFileName } from "components/CarespacePage/Tabs/CarespaceMainTab/carespaceMainTabUtil";
import { AssignCareTeamModal } from "components/HomePage/CareCentralHome/Carespaces/AssignCareTeamModal";
import type { CarespaceTableType } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import {
  CarespaceTableFields,
  DYAD,
} from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import {
  getCarespaceNameFromCarespace,
  getCarespaceStatusFromCarespace,
  getUserAdloDobFromCarespace,
  getUserAdloDyadFromCarespace,
  getUserAdloSexFromCarespace,
  getUserRoleMapFromCarespace,
  useFilteredCarespaces,
} from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/util";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter";

import {
  PAFStatus,
  Sex,
} from "components/CarespacePage/PAFSubmission/pafEnums";
import { GuideAlignmentStatus } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Badge } from "shared/ui/badge";
import { DataTable } from "shared/ui/data-table";

const columnHelper = createColumnHelper<CarespaceTableType>();

const columns = [
  columnHelper.accessor(CarespaceTableFields.CARESPACE, {
    header: "Carespace",
  }),
  columnHelper.accessor(CarespaceTableFields.DOB, {
    header: "DOB",
  }),
  columnHelper.accessor(CarespaceTableFields.SEX, {
    header: "Sex",
    cell: (ctx) => {
      const value = ctx.getValue();

      return (
        <Badge
          color={
            value === Sex.MALE
              ? "blue"
              : value === Sex.FEMALE
              ? "salmon"
              : "gray"
          }>
          {value}
        </Badge>
      );
    },
  }),
  columnHelper.accessor(CarespaceTableFields.DYAD, {
    header: "Dyad",
    cell: (ctx) => {
      const value = ctx.getValue();

      return (
        <Badge
          color={
            value === DYAD.HIGH
              ? "salmon"
              : value === DYAD.LOW
              ? "green"
              : value === DYAD.MODERATE
              ? "orange"
              : "gray"
          }>
          {value}
        </Badge>
      );
    },
  }),
  columnHelper.accessor(CarespaceTableFields.ORG, {
    header: "Org",
    cell: (ctx) => <Badge color="purple">{ctx.getValue()}</Badge>,
  }),
  columnHelper.accessor(CarespaceTableFields.STATUS, {
    header: "Status",
    cell: (ctx) => {
      const value = ctx.getValue();
      const compValue = value.toUpperCase();

      return (
        <Badge
          color={
            compValue === GuideAlignmentStatus.ALIGNED
              ? "brightGreen"
              : compValue === GuideAlignmentStatus.SUBMITTED
              ? "purple"
              : compValue === PAFStatus.READY
              ? "blue"
              : compValue === PAFStatus.DRAFT
              ? "orange"
              : "gray"
          }>
          {value}
        </Badge>
      );
    },
  }),
  columnHelper.accessor(CarespaceTableFields.DOCTOR, {
    header: "Doctor",
  }),
  columnHelper.accessor(CarespaceTableFields.PCG, {
    header: "PCG",
  }),
  columnHelper.accessor(CarespaceTableFields.CN, {
    header: "CN",
  }),
  columnHelper.accessor(CarespaceTableFields.PCP, {
    header: "PCP",
  }),
];

interface CarespaceTableProps {
  carespaces: NetworksWithAdlosAndCaregivers[];
  isLoading: boolean;
  onAddCarespace: () => void;
}

const PENDING_STATUS = "Pending";
const TBD_STATUS = "TBD";

export const CarespaceTable: FC<CarespaceTableProps> = ({
  carespaces,
  isLoading,
  onAddCarespace,
}) => {
  const navigate = useAppNavigate();
  const { org, dob, sex, dyad, doctor, pcg, cn, pcp, status, carespace } =
    useCarespaceFilterStore();
  const [tableData, setTableData] = useState<CarespaceTableType[]>([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const { isSuperSuperUser, allOrgIdentities } = useOrgs();
  const orgRole = allOrgIdentities?.find(
    (role) => role.organization_id === org
  );
  const filteredData = useFilteredCarespaces(carespaces);

  useEffect(() => {
    const data = filteredData.map((carespace) => {
      const userRolesMap = getUserRoleMapFromCarespace(carespace);
      return {
        [CarespaceTableFields.ID]: carespace.id,
        [CarespaceTableFields.CARESPACE]:
          getCarespaceNameFromCarespace(carespace),
        [CarespaceTableFields.DOB]: getUserAdloDobFromCarespace(carespace),
        [CarespaceTableFields.SEX]: getUserAdloSexFromCarespace(carespace),
        [CarespaceTableFields.DYAD]: getUserAdloDyadFromCarespace(carespace),
        [CarespaceTableFields.ORG]: carespace.organization?.name,
        [CarespaceTableFields.STATUS]:
          getCarespaceStatusFromCarespace(carespace),
        [CarespaceTableFields.PCP]: carespace.primary_care_physician?.name,
        [CarespaceTableFields.DOCTOR]: userRolesMap[NetworkRoleType.DOCTOR],
        [CarespaceTableFields.PCG]:
          userRolesMap[NetworkRoleType.PRIMARY_CAREGIVER] ?? PENDING_STATUS,
        [CarespaceTableFields.CN]:
          userRolesMap[NetworkRoleType.CARE_NAVIGATOR] ?? TBD_STATUS,
      } as CarespaceTableType;
    });
    setTableData(data);
  }, [
    carespaces,
    org,
    dob,
    sex,
    dyad,
    doctor,
    pcg,
    cn,
    pcp,
    status,
    carespace,
  ]);

  if (isLoading) {
    return <LoadingSpinner className="w-6 h-6" />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center mt-4">
        <div
          className="flex items-center"
          style={{ flex: 2, marginTop: "0" }}>
          {(orgRole?.is_superuser || isSuperSuperUser) && (
            <>
              <ButtonWithIcon
                text="Add Carespace"
                icon={IconOption.PLUS}
                onClick={onAddCarespace}
              />
              <div className="ml-4">
                <ButtonWithIcon
                  text="Change Care Team"
                  icon={IconOption.EDIT}
                  disabled={Object.keys(selectedRows).length === 0}
                  onClick={() => setIsAssignModalOpen(true)}
                />
              </div>
              <ButtonWithIcon
                disabled={Object.keys(selectedRows).length === 0}
                onClick={() => {
                  const fileName = buildPAFFileName(null);
                  downloadPafFile(Object.keys(selectedRows), fileName);
                }}
                className="ml-4"
                text="Download PAFs"
                icon={IconOption.DOWNLOAD}
              />
            </>
          )}
        </div>
      </div>

      <DataTable
        columns={columns}
        data={tableData}
        onRowClick={(row) =>
          navigate({
            path: CareCentralRoute.CARESPACE,
            params: {
              carespaceId: row.id,
            },
          })
        }
        rowSelection={{
          selectedRows,
          setSelectedRows,
          getRowId: (row) => row[CarespaceTableFields.ID],
        }}
      />

      <AssignCareTeamModal
        isOpen={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        selectedCarespaces={Object.keys(selectedRows)}
      />
    </div>
  );
};
