import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUserPrescriptionsQuery } from "backend/resources/userPrescription/userPrescription";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useUserStore } from "state/user";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { BottomSheet } from "components/Sheet";
import { MedicationsDeletePrescription } from "components/NanasDay/ActionButtons/MedicationsDeletePopup";

export default function MobileMedicationsActionButtons({
  close,
}: {
  close: () => void;
}) {
  const { isMobile } = useWindowSize();
  const navigate = useAppNavigate();
  const authUser = useUserStore((state) => state.user);
  const [isOpen, setIsOpen] = useState(true);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const { dispensable_drug_id } = useParams();
  const { isLoadingUserPrescriptions, userPrescriptions } =
    useUserPrescriptionsQuery();

  const prescription = userPrescriptions?.find(
    (prescription) => prescription.dispensable_drug_id === dispensable_drug_id
  );

  if (!isMobile) return null;

  return (
    <>
      {isDeletePopupOpen && (
        <MedicationsDeletePrescription
          drugId={prescription?.id}
          onClose={() => {
            setIsDeletePopupOpen(false);
            close();
          }}
        />
      )}
      <BottomSheet
        isOpen={isOpen}
        onClose={close}>
        <div className="flex flex-col gap-4 items-start pl-10 pb-10">
          <h1 className="text-lg">Prescription Actions</h1>
          <ButtonWithIcon
            text="Edit"
            icon={IconOption.EDIT}
            onClick={() => {
              if (prescription?.dispensable_drug_id) {
                navigate({
                  path: CarePilotRoute.DAY_PRESCRIPTION_EDIT,
                  params: {
                    dispensable_drug_id: prescription.dispensable_drug_id,
                  },
                });
              }
            }}
          />
          <ButtonWithIcon
            onClick={() => {
              setIsDeletePopupOpen(true);
              setIsOpen(false);
            }}
            text={`Delete prescription`}
            icon={IconOption.TRASH}
          />
        </div>
      </BottomSheet>
    </>
  );
}
