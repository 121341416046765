import type { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { NetworkRoleType } from "backend/resources/userRole/types";
import type { PAFStatus } from "components/CarespacePage/PAFSubmission/pafEnums";
import { Sex } from "components/CarespacePage/PAFSubmission/pafEnums";
import { getGuideAlignmentPillLabel } from "components/CarespacePage/Tabs/CarespaceMainTab/carespaceMainTabUtil";
import type { GuideAlignmentStatus } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import { useMemo } from "react";
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter";
import { capitalizeFirstLetter, isMatch } from "utils";
import { DYAD } from "./constants";

const TBD_STATUS = "TBD";

export const getCarespaceNameFromCarespace = (
  carespace: NetworksWithAdlosAndCaregivers
) => {
  const userAdlo = carespace.user_adlo?.[0];
  if (!userAdlo?.first_name && !userAdlo?.last_name) {
    return carespace.name || "";
  }
  return (
    `${userAdlo.first_name || ""} ${userAdlo.last_name || ""}`.trim() || ""
  );
};

export const getCarespaceStatusFromCarespace = (
  carespace: NetworksWithAdlosAndCaregivers
) => {
  const pafSubmission =
    carespace.paf_submission?.length > 0
      ? carespace.paf_submission.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )[0]
      : null;
  return getGuideAlignmentPillLabel(
    pafSubmission?.status as PAFStatus,
    carespace.guide_status as GuideAlignmentStatus
  );
};

export const getUserRoleMapFromCarespace = (
  carespace: NetworksWithAdlosAndCaregivers
) => {
  const rolesMap: Partial<Record<NetworkRoleType, string>> = {};
  carespace.user_role.forEach(({ role, user }) => {
    rolesMap[role] = `${user?.first_name} ${user?.last_name}`;
  });
  return rolesMap;
};

export const getUserAdloDobFromCarespace = (
  carespace: NetworksWithAdlosAndCaregivers
) => {
  const birthday = carespace.user_adlo[0]?.birthday;
  if (!birthday) return null; // Handle null or undefined birthday
  const date = new Date(birthday);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getUserAdloSexFromCarespace = (
  carespace: NetworksWithAdlosAndCaregivers
) => {
  const formattedSex = capitalizeFirstLetter(carespace.user_adlo[0]?.sex);
  if (!formattedSex || ![Sex.MALE, Sex.FEMALE].includes(formattedSex as Sex))
    return Sex.UNKNOWN;
  return formattedSex;
};

export const getUserAdloDyadFromCarespace = (
  carespace: NetworksWithAdlosAndCaregivers
) => {
  const formattedDyad = capitalizeFirstLetter(carespace.user_adlo[0]?.dyad);
  if (!formattedDyad || !Object.values(DYAD).includes(formattedDyad as DYAD))
    return DYAD.NA;
  return formattedDyad;
};

export const useFilteredCarespaces = (
  carespaces: NetworksWithAdlosAndCaregivers[]
) => {
  const filters = useCarespaceFilterStore();

  return useMemo(
    () =>
      carespaces.filter((carespace) => {
        const userRolesMap = getUserRoleMapFromCarespace(carespace);
        const isCarespaceMatch = isMatch(
          filters.carespace,
          getCarespaceNameFromCarespace(carespace)
        );
        const isOrgMatch = isMatch(
          filters.org,
          carespace.organization_id,
          true
        );
        const isStatusMatch = isMatch(
          filters.status,
          getCarespaceStatusFromCarespace(carespace)
        );
        const isDobMatch = isMatch(
          filters.dob,
          getUserAdloDobFromCarespace(carespace)
        );
        const isSexMatch = isMatch(
          filters.sex,
          getUserAdloSexFromCarespace(carespace)
        );
        const isDyadMatch = isMatch(
          filters.dyad,
          getUserAdloDyadFromCarespace(carespace)
        );
        const isPcpMatch = isMatch(
          filters.pcp,
          carespace.primary_care_physician?.name
        );
        const isDoctorMatch = isMatch(
          filters.doctor,
          userRolesMap[NetworkRoleType.DOCTOR]
        );
        const isPcgMatch = isMatch(
          filters.pcg,
          userRolesMap[NetworkRoleType.PRIMARY_CAREGIVER]
        );
        const isCnMatch =
          filters.cn === TBD_STATUS
            ? !userRolesMap[NetworkRoleType.CARE_NAVIGATOR]
            : isMatch(filters.cn, userRolesMap[NetworkRoleType.CARE_NAVIGATOR]);

        return (
          isOrgMatch &&
          isCarespaceMatch &&
          isStatusMatch &&
          isDobMatch &&
          isSexMatch &&
          isDyadMatch &&
          isDoctorMatch &&
          isPcgMatch &&
          isCnMatch &&
          isPcpMatch
        );
      }),
    [carespaces, filters]
  );
};
