import { Popover } from "@mui/material";
import TrashIcon from "assets/trash-new.svg?react";
import { useDrugInformationQuery } from "backend/functions";
import {
  useDeleteUserPrescription,
  useUserPrescriptionsQuery,
} from "backend/resources/userPrescription/userPrescription";
import { useScheduledDosesByIdQuery } from "backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { LoadingSpinner } from "components/LoadingSpinner";
import MobileMedicationsActionButtons from "components/NanasDay/ActionButtons/MedicationsMobileActionButtons";
import { getPluralDrugDoseDesc } from "components/NanasDay/utils";
import { PageMainHeader } from "components/PageMainHeader";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUserStore } from "state/user";
import { capitalizeFirstLetter } from "utils";

export function PrescriptionDetailView() {
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const authUser = useUserStore((state) => state.user);

  const { isMobile } = useWindowSize();
  const { dispensable_drug_id } = useParams();
  const { isLoadingUserPrescriptions, userPrescriptions } =
    useUserPrescriptionsQuery();
  const prescription = userPrescriptions?.find(
    (prescription) => prescription.dispensable_drug_id === dispensable_drug_id
  );

  const [selectedTab, setSelectedTab] = useState<"dosing" | "information">(
    "dosing"
  );

  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  return (
    <div className="flex flex-col h-full gap-4">
      {/* buttons row */}
      <div className="flex justify-between items-center">
        {/* left bar - back button */}
        {isMobile ? (
          <div>
            <ButtonWithIcon
              onClick={() => {
                navigateBack();
              }}
              disabled={false}
              text={"Back"}
              icon={IconOption.BACK_ARROW}
            />
          </div>
        ) : (
          <div className="-ml-4">
            <ButtonWithIcon
              onClick={() => {
                navigateBack();
              }}
              disabled={false}
              text={"Back"}
              icon={IconOption.BACK_ARROW}
            />
          </div>
        )}
        {/* right bar  */}
        <div className="flex gap-4">
          {!isMobile ? (
            <ButtonWithIcon
              size={"small"}
              text="Edit"
              icon={IconOption.EDIT}
              onClick={() => {
                if (prescription?.dispensable_drug_id) {
                  navigate({
                    path: CarePilotRoute.DAY_PRESCRIPTION_EDIT,
                    params: {
                      dispensable_drug_id: prescription.dispensable_drug_id,
                    },
                  });
                }
              }}
            />
          ) : null}
        </div>
      </div>
      {prescription && !isLoadingUserPrescriptions ? (
        <div className="flex flex-col gap-4">
          {/* drug header */}
          <PageMainHeader
            text={capitalizeFirstLetter(
              prescription?.drug_name_desc || "Prescription"
            )}
          />
          {/* tabs */}
          <div className={`flex gap-4 ${isMobile ? "justify-between" : ""}`}>
            <button
              onClick={() => {
                setSelectedTab("dosing");
              }}
              className={`text-xl font-light  ${
                selectedTab === "dosing" && "text-brand-orange"
              }`}>
              Dosing
            </button>
            {/* <button
              onClick={() => {
                setSelectedTab("information");
              }}
              className={`text-xl font-light  ${
                selectedTab === "information" && "text-brand-orange"
              }`}>
              Information
            </button> */}
          </div>
          {/* description */}
          {selectedTab === "information" ? <InformationTab /> : <DosingTab />}
        </div>
      ) : isLoadingUserPrescriptions ? (
        <div className="flex w-full h-full justify-center items-center">
          <LoadingSpinner className="w-20 h-20" />
        </div>
      ) : (
        <div>Error loading prescription, try clicking back.</div>
      )}
      {isActionSheetOpen && (
        <MobileMedicationsActionButtons
          close={() => setIsActionSheetOpen(false)}
        />
      )}
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => setIsActionSheetOpen(true)}
            text={""}
            icon={IconOption.MORE}
          />
        }
      />
    </div>
  );
}

function DosingTab() {
  const authUser = useUserStore((state) => state.user);
  const authUserId = authUser?.id;

  const { dispensable_drug_id } = useParams();

  const { userPrescriptions } = useUserPrescriptionsQuery();

  const prescription = userPrescriptions?.find(
    (prescription) => prescription.dispensable_drug_id === dispensable_drug_id
  );

  const {
    asNeededScheduledDoses,
    morningScheduledDoses,
    afternoonScheduledDoses,
    eveningScheduledDoses,
    bedtimeScheduledDoses,
  } = useScheduledDosesByIdQuery(authUserId, prescription?.id);

  return (
    <div className="flex flex-col h-full gap-4 pt-4 pb-28">
      <form className="flex flex-col gap-6 max-w-[80ch] ">
        {/* dosage strength */}
        <div className="grid grid-cols-[3fr,2fr]">
          <p>What is the prescribed dosage strength?</p>
          <p>{prescription?.med_strength}</p>
        </div>
        {/* per bottle */}
        <div className="grid grid-cols-[3fr,2fr]">
          {/* text */}
          <div>
            How many{" "}
            {getPluralDrugDoseDesc(prescription?.dose_form_desc || "doses")} are
            in a full bottle?
          </div>
          {/* value */}
          <div>
            {prescription?.doses_fill_qty != null
              ? prescription?.doses_fill_qty
              : "N/A"}
          </div>
        </div>
        {/* remaining in the bottle */}
        <div className="grid grid-cols-[3fr,2fr]">
          {/* text */}
          <div>
            How many{" "}
            {getPluralDrugDoseDesc(prescription?.dose_form_desc || "doses")}{" "}
            remaining?
          </div>
          {/* value */}
          <div>
            {prescription?.doses_remaining != null
              ? prescription?.doses_remaining
              : "N/A"}
          </div>
        </div>
        {/* use instructions */}
        <div className="flex flex-col">
          {/* when */}
          <div className="grid grid-cols-1 md:grid-cols-[3fr,2fr] gap-4 md:gap-0 ">
            {/* label */}
            <div>How should it be given? (check all that apply)</div>
            {/* input */}
            <div className="flex flex-col gap-6 mx-auto md:mx-0 ">
              {/* morning */}
              <div className="grid grid-cols-[15ch,auto] items-center">
                <DefaultCheckbox
                  checked={
                    morningScheduledDoses?.length != null &&
                    morningScheduledDoses?.length > 0
                  }
                  disabled={true}
                  onChange={() => {
                    // noop
                  }}
                  label="Morning"
                />
                <p>
                  {(morningScheduledDoses &&
                    morningScheduledDoses[0]?.dose_count) ||
                    0}{" "}
                  {getPluralDrugDoseDesc(
                    prescription?.dose_form_desc || "doses"
                  )}
                </p>
              </div>
              {/* afternoon */}
              <div className="grid grid-cols-[15ch,auto] items-center">
                <DefaultCheckbox
                  checked={
                    afternoonScheduledDoses?.length != null &&
                    afternoonScheduledDoses?.length > 0
                  }
                  disabled={true}
                  onChange={() => {
                    // noop
                  }}
                  label="Afternoon"
                />
                <p>
                  {(afternoonScheduledDoses &&
                    afternoonScheduledDoses[0]?.dose_count) ||
                    0}{" "}
                  {getPluralDrugDoseDesc(
                    prescription?.dose_form_desc || "doses"
                  )}
                </p>
              </div>
              {/* evening */}
              <div className="grid grid-cols-[15ch,auto] items-center">
                <DefaultCheckbox
                  checked={
                    eveningScheduledDoses?.length != null &&
                    eveningScheduledDoses?.length > 0
                  }
                  disabled={true}
                  onChange={() => {
                    // noop
                  }}
                  label="Evening"
                />
                <p>
                  {(eveningScheduledDoses &&
                    eveningScheduledDoses[0]?.dose_count) ||
                    0}{" "}
                  {getPluralDrugDoseDesc(
                    prescription?.dose_form_desc || "doses"
                  )}
                </p>
              </div>
              {/* bedtime */}
              <div className="grid grid-cols-[15ch,auto] items-center">
                <DefaultCheckbox
                  checked={
                    bedtimeScheduledDoses?.length != null &&
                    bedtimeScheduledDoses?.length > 0
                  }
                  disabled={true}
                  onChange={() => {
                    // noop
                  }}
                  label="Bedtime"
                />
                <p>
                  {(bedtimeScheduledDoses &&
                    bedtimeScheduledDoses[0]?.dose_count) ||
                    0}{" "}
                  {getPluralDrugDoseDesc(
                    prescription?.dose_form_desc || "doses"
                  )}
                </p>
              </div>
              {/* as needed */}
              <span className="mx-auto">-OR-</span>
              {/* as needed checkbox */}
              <div className="grid grid-cols-[15ch,auto] items-center pb-3">
                <DefaultCheckbox
                  checked={
                    asNeededScheduledDoses != null &&
                    asNeededScheduledDoses.length > 0
                  }
                  disabled={true}
                  onChange={(isChecked) => {
                    //  noop
                  }}
                  label="As Needed"
                />
              </div>
            </div>
          </div>
        </div>
        {/* max doses per day */}
        <div className="grid grid-cols-[3fr,2fr] items-center">
          <span>
            Max {getPluralDrugDoseDesc(prescription?.dose_form_desc || "dose")}{" "}
            per day?
          </span>
          <p>
            {(asNeededScheduledDoses &&
              asNeededScheduledDoses[0]?.max_doses_per_day) ||
              "N/A"}
          </p>
        </div>
        {/* with food? */}
        <div className="grid grid-cols-[3fr,2fr]">
          {/* text */}
          <div>Take with food?</div>
          {/* value */}
          <p>{prescription?.take_with_food ? "Yes" : "No"}</p>
        </div>
        {/* doctors comments */}
        <div className="flex flex-col gap-3">
          {/* text */}
          <div>Doctor’s Comments</div>
          {/* value */}
          <p className="w-full min-h-[150px] border border-faint-gray p-3">
            {prescription?.doctor_comments}
          </p>
        </div>
      </form>
    </div>
  );
}

function InformationTab() {
  const { dispensable_drug_id } = useParams();

  const { isLoadingDrugInformation, drugInformation } =
    useDrugInformationQuery(dispensable_drug_id);

  return (
    <div>
      {isLoadingDrugInformation ? (
        <div className="flex w-full h-full justify-center items-center">
          <LoadingSpinner className="w-20 h-20" />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <label className="text-lg">Description</label>
          <div>{drugInformation.description || "N/A"}</div>
        </div>
      )}
    </div>
  );
}

export function PrescriptionDeleteButtonPopover({
  drugId,
}: {
  drugId?: string;
}) {
  const deleteUserPrescription = useDeleteUserPrescription().mutateAsync;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigateBack = useNavigateBack();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Pass event.currentTarget
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    if (drugId) {
      await deleteUserPrescription(drugId);
      handleClose();
      // navigate back x2
      navigateBack();
      navigateBack();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "nanasDay-delete-popover" : undefined;

  return (
    <div>
      <button
        disabled={!drugId}
        aria-describedby={id}
        className={`flex items-center gap-2 p-2 text-sm rounded-sm ${
          open && "bg-zinc-50"
        }`}
        onClick={handleClick}>
        <TrashIcon className="w-6 h-6" />
        Delete
      </button>
      <Popover
        elevation={2}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="flex flex-col items-center w-96 text-sm p-6">
          <span className="p-2 text-center">
            Are you sure you want to delete this prescription?
          </span>
          <div className="flex gap-3">
            <ButtonWithIcon
              onClick={handleClose}
              className="p-2"
              text="Cancel"
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={handleDelete}
              className="p-2"
              text="Yes, delete"
              icon={IconOption.CHECKMARK}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
}
