import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

import type {
  ColumnDef,
  OnChangeFn,
  RowSelectionState,
  TableOptions,
} from "@tanstack/react-table";

import { Checkbox } from "shared/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/ui/table";
import { cn } from "shared/utils/cn";

export interface DataTableProps<TData> {
  // See https://github.com/TanStack/table/issues/4382#issuecomment-2427090881 for type explanation
  columns: {
    [K in keyof TData]: ColumnDef<TData, TData[K]>;
  }[keyof TData][];
  data: TData[];
  onRowClick?: (row: TData) => void;
  rowSelection?: {
    selectedRows: RowSelectionState;
    setSelectedRows: OnChangeFn<RowSelectionState>;
    getRowId?: TableOptions<TData>["getRowId"];
    enableMultiRowSelection?: TableOptions<TData>["enableMultiRowSelection"];
    enableSubRowSelection?: TableOptions<TData>["enableSubRowSelection"];
  };
}

export function DataTable<TData>({
  columns: propColumns,
  data,
  onRowClick,
  rowSelection,
}: DataTableProps<TData>) {
  const { selectedRows, setSelectedRows, ...rowSelectionTableOptions } =
    rowSelection || {};

  const columns = useMemo<DataTableProps<TData>["columns"]>(
    () => [
      ...(rowSelection
        ? [
            {
              id: "row-selection",
              header: ({ table }) => (
                <div className="flex items-center justify-center">
                  <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                      table.toggleAllPageRowsSelected(value)
                    }
                    aria-label="Select all"
                  />
                </div>
              ),
              cell: ({ row }) => (
                <div className="flex items-center justify-center">
                  <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(value)}
                    aria-label="Select row"
                  />
                </div>
              ),
              enableSorting: false,
              enableHiding: false,
            } as ColumnDef<TData>,
          ]
        : []),
      ...propColumns,
    ],
    [propColumns, rowSelection]
  );

  const table = useReactTable<TData>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: !!rowSelection,
    onRowSelectionChange: setSelectedRows,
    state: {
      rowSelection: selectedRows,
    },
    ...rowSelectionTableOptions,
  });

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>

      <TableBody>
        {table.getRowModel().rows?.length > 0 ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              {...(rowSelection
                ? { "data-state": row.getIsSelected() && "selected" }
                : {})}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  onClick={
                    cell.column.columnDef.id !== "row-selection" && onRowClick
                      ? () => onRowClick(row.original)
                      : undefined
                  }
                  className={cn(
                    cell.column.columnDef.id !== "row-selection" && onRowClick
                      ? "cursor-pointer"
                      : ""
                  )}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={columns.length}
              className="text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
