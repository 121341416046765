/**
 * @fileoverview Reset Password page.
 */

import type { ChangeEvent, FormEvent, MouseEvent } from "react";
import { useEffect, useState } from "react";
import { track } from "analytics/track";
import { supabase } from "clients/supabaseClient";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useUserStore } from "state/user";
import { Button } from "components/Button";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TextInput } from "components/TextInput";

const REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL;

/**
 * Component for the Reset Password page.
 */
export function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState<string | null>();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useAppNavigate();
  const authUser = useUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [showResendEmail, setShowResendEmail] = useState(false);
  const [email, setEmail] = useState<string>();
  const [showResendEmailSuccess, setShowResendEmailSuccess] = useState(false);

  useEffect(() => {
    track({ type: "Page View", properties: { name: "reset_password" } });
  }, []);

  useEffect(() => {
    if (authUser) {
      setShowResendEmail(false);
      setIsLoading(false);
    } else {
      setShowResendEmail(true);
      setIsLoading(false);
    }
  }, [authUser]);

  async function handleResetPassword(
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    if (!newPassword) {
      setError("You must enter a new password.");
    } else {
      const { data, error } = await supabase.auth.updateUser({
        password: newPassword,
      });
      if (error) {
        setError("An error occurred.");
        setShowSuccessMessage(false);
      }
      if (data) {
        setShowSuccessMessage(true);
      }
    }
  }

  async function handleResendResetPasswordEmail(
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();

    if (!email) {
      setError("You must enter your email.");
      return;
    }
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${REDIRECT_URL}/reset-password`,
    });

    if (!error) {
      setShowResendEmailSuccess(true);
    } else {
      setError("Something went wrong.");
    }
  }

  function onChangeNewPassword(e: ChangeEvent<HTMLInputElement>) {
    setError(null);
    setShowSuccessMessage(false);
    setNewPassword(e.currentTarget.value);
  }

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setError(null);
    setShowSuccessMessage(false);
    setEmail(e.currentTarget.value);
  }

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-20 h-20" />
      </div>
    );
  }

  if (showSuccessMessage) {
    return (
      <div className="bg-brand-blue w-full h-full flex items-center justify-center">
        <div className="w-[70%] max-w-xl bg-white px-12 pt-20 pb-12 rounded-md shadow-sm border border-faint-gray flex flex-col items-center gap-4">
          <h1 className="font-semibold text-3xl">
            Successfully reset password!
          </h1>
          <Button
            intent="primary"
            onClick={() =>
              navigate({
                path: SharedRoute.HOME,
              })
            }>
            Return Home
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-brand-blue w-full h-full flex items-center justify-center">
      <div className="w-[70%] max-w-xl bg-white px-12 pt-20 pb-12 rounded-md shadow-sm border border-faint-gray flex flex-col items-center gap-4">
        <h1 className="font-semibold text-3xl">Reset Your Password</h1>
        {showResendEmailSuccess ? (
          <p>Success! Please check your email.</p>
        ) : showResendEmail ? (
          <form
            className="flex flex-col items-center w-full gap-3"
            onSubmit={handleResendResetPasswordEmail}>
            <p>Not authenticated.</p>
            <TextInput
              data-hj-allow
              type="text"
              onChange={onChangeEmail}
              placeholder="Enter your email"
              value={email}
            />
            <Button
              onClick={handleResendResetPasswordEmail}
              intent="primary">
              Resend Email
            </Button>
          </form>
        ) : (
          <form
            onSubmit={handleResetPassword}
            className="w-full flex flex-col items-center p-6 py-3">
            <div className="w-full flex flex-col">
              <label
                className="text-black text-xs ml-1 mt-3"
                htmlFor="password">
                New Password
              </label>
              <TextInput
                data-hj-allow
                type="password"
                onChange={onChangeNewPassword}
                placeholder="Enter your new password"
                value={newPassword}
              />
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <Button
              className="w-full mt-4"
              onClick={handleResetPassword}>
              Reset Password
            </Button>
          </form>
        )}
        <button
          onClick={() =>
            navigate({
              path: SharedRoute.HOME,
            })
          }>
          Don't need to reset your password?{" "}
          <span className="text-blue-500">Return home</span>
        </button>
      </div>
    </div>
  );
}
